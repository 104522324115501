import { useState, useContext } from 'react';
import { Document, Page } from 'react-pdf';
import starPiece from "../../api/starPiece";
import { AccountContext } from "../../stores/Account";
import LoadingView from "./LoadingView";
import useClickOutside from "../../hooks/useClickOutside";
import viewPiece from '../../api/viewPiece';
import AddToList from './AddToList';
import FailedToLoadPDFView from './FailedToLoadPDFView';
import FlatPNGEmbed from './FlatPNGEmbed';
const StarIcon = ({ filled, onClick }) => (
  <svg
    style={{ pointerEvents: 'auto' }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`${filled ? "text-yellow-300" : "text-gray-400"} hover:scale-110 transition-transform duration-200`}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

function PieceCard({ piece }) {
  const [starred, setStarred] = useState(piece.starred === 1);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { user } = useContext(AccountContext);

  const handleError = (error) => {
    console.error(error);
    setHasError(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutside, isDisabled);

  const star = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    starPiece(user, piece.id).then(() => {
      setStarred((starred) => !starred)
    }).catch((err) => {
      console.log(err);
    });
  }

  const view = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    viewPiece(user, piece.id)
  }

  return (
    <div
      onClick={() => window.location.href = "/dashboard/music/piece/" + piece.id}
      className="group relative bg-white dark:bg-gray-800 rounded-xl shadow-md hover:shadow-lg dark:hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300"
      style={{ width: '220px', height: '400px' }}
    >
      {/* Preview Section */}
      <div className="relative w-full h-[240px] bg-gray-50 dark:bg-gray-900">
        <div className="overflow-hidden rounded-t-xl">
          <div className="absolute inset-0">
            {piece.type === 'pdf' ? (
              hasError ? (
                <FailedToLoadPDFView width={'220px'} height={'400px'} />
              ) : (
                <Document
                  file={piece.pdf_link}
                  className="w-full no-scrollbar overflow-hidden"
                  onLoadError={handleError}
                  loading={<LoadingView />}
                >
                  <Page pageNumber={1} scale={0.3} />
                </Document>
              )
            ) : piece.type === 'musicxml' ?
              piece.thumbnail ? (
                <img
                  src={piece.thumbnail}
                  alt={piece.title}
                  className="absolute inset-0 w-full h-full object-contain bg-white"
                />
              ) : (
                <FlatPNGEmbed
                  sharingKey={piece.flat_sharing_key}
                  scoreId={piece.flat_score_id}
                  height={240}
                />
              ) : (
                <LoadingView />
              )}
          </div>
        </div>

        {/* Overlay Elements */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

        <div className="absolute top-3 right-3 flex items-center space-x-2">
          <StarIcon filled={starred} onClick={star} />
        </div>

        <div className="absolute bottom-3 right-3">
          <span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-violet-600 text-white shadow-sm">
            Piano Solo
          </span>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4 flex flex-col justify-between h-[160px]">
        <div className="flex items-start justify-between gap-2">
          <div className="flex-1">
            <h3 className="font-bold text-gray-900 dark:text-white line-clamp-2 leading-tight mb-1">
              {piece.title}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              {piece.composer_fname} {piece.composer_lname}
            </p>
          </div>

          {/* Menu Button */}
          <div className="relative flex-shrink-0">
            <button
              onClick={handleClick}
              className="p-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
            >
              <svg className="w-5 h-5 text-gray-600 dark:text-gray-400" fill="currentColor" viewBox="0 0 16 3">
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div
                ref={ref}
                className="absolute right-0 mt-2 z-50 w-48 bg-white dark:bg-gray-700 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  <AddToList
                    setDisabled={setIsDisabled}
                    piece_id={piece.id}
                    button_view={
                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600">
                        Add to List
                      </button>
                    }
                  />
                  <button
                    onClick={star}
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    {starred ? "Unstar Piece" : "Star Piece"}
                  </button>
                  <button
                    onClick={view}
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    Mark as Viewed
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PieceCard;