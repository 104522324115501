import API from ".";

const bulkPieceUpload = (user, file_names, file_ids, file_type, is_private) => {

  let obj = {
    "file_names": file_names,
    "file_ids": file_ids,
    "file_type": file_type ?? '',
    "private": is_private ? 1 : 0
  }

  return new API(user).postRequest('/piece/bulkupload', obj);
}

export default bulkPieceUpload;