import { useState, useEffect, useContext } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AddNewPiece from "../components/AddNewPiece";
import getAlbumData from "../../api/getAlbumData";
import { useParams } from "react-router-dom";
import { AccountContext } from "../../stores/Account";
import { daysSinceDate } from "../../utils/datetime";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import reorderAlbumPieces from "../../api/reorderAlbumPieces";
import NewMusicCard from "../components/NewMusicCard";
import PieceCard from "../components/PieceCard";
import EditAlbumModal from '../components/EditAlbumModal';
import ConcertHall from "../../assets/stock/concerthall.png"
import useStateParam from '../../hooks/useStateParam';
import Piece from "./Piece";
import updateAlbumMetadata from '../../api/updateAlbumMetadata';

// Create a new SortablePieceCard component
const SortablePieceCard = ({ piece, index, isReordering }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: piece.album_piece_id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`relative ${isReordering ? 'cursor-move' : ''}`}
      {...(isReordering ? { ...attributes, ...listeners } : {})}
    >
      {isReordering && (
        <div className="absolute -top-2 -right-2 z-10 w-6 h-6 bg-gray-700/80 rounded-full flex items-center justify-center">
          <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
      )}
      <PieceCard
        key={piece.id}
        piece={piece}
        index={index}
        onClick={isReordering ? undefined : () => window.location.href = `/dashboard/music/piece/${piece.id}`}
      />
    </div>
  );
};

function Album(props) {

  const { user } = useContext(AccountContext);
  const { id } = useParams();
  const [mode, setMode] = useStateParam('mode', ['play', 'view'], 'view');
  const [albumData, setAlbumData] = useState({ pieces: [], name: "", image: "", last_updated: "" });
  const [pieces, setPieces] = useState([]);
  const [mix, setMix] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPieceIndex, setCurrentPieceIndex] = useState(0);
  const [isReordering, setIsReordering] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor)
  );

  useEffect(() => {
    if (user && id) {
      getAlbumData(user, id, mix > 0).then((data) => {
        setAlbumData(data);
        setPieces(data.pieces);
      });
    }
  }, [user, id, mix]);

  const handleNext = () => {
    if (currentPieceIndex < albumData.pieces.length - 1) {
      setCurrentPieceIndex(currentPieceIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPieceIndex > 0) {
      setCurrentPieceIndex(currentPieceIndex - 1);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPieces((items) => {
        const oldIndex = items.findIndex(item => item.album_piece_id === active.id);
        const newIndex = items.findIndex(item => item.album_piece_id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        reorderAlbumPieces(user, id, newItems.map(piece => piece.id));
        return newItems;
      });
    }
  };

  const handleUpdateAlbum = (name, picture) => {
    setIsUpdating(true);
    try {
      console.log('updating album', name, picture);
      updateAlbumMetadata(user, id, name, picture).then(() => {
        setAlbumData(prev => ({
          ...prev,
          name: name,
          image: picture
        }));
        setIsEditModalOpen(false);
        setIsUpdating(false);
        window.location.reload();
      });
    } catch (error) {
      console.error('Failed to update album:', error);
      setIsUpdating(false);
    }
  };

  return (
    <div className="box-border overflow-x-hidden w-full rounded-lg dark:bg-gray-800 bg-white min-h-full">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="relative group">
                <img
                  src={albumData?.picture || ConcertHall}
                  alt={albumData?.name}
                  className="w-16 h-16 rounded-lg object-cover"
                />
                <button
                  onClick={() => setIsEditModalOpen(true)}
                  className="absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 group-hover:opacity-100 rounded-lg transition-opacity duration-200"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </button>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  {albumData ? albumData.name : ""}
                </h2>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Updated {albumData ? (daysSinceDate(albumData.last_updated) > 0 ? daysSinceDate(albumData.last_updated).toString() + "d ago" : "today") : ""}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              {mode === 'play' ? (
                <div className="flex items-center">
                  <div className="flex rounded-lg overflow-hidden">
                    <button
                      onClick={handlePrevious}
                      disabled={currentPieceIndex === 0}
                      className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-l-lg hover:bg-violet-100 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                    <div className="flex items-center px-3 py-2 text-sm font-medium text-violet-600 bg-violet-50 border-l border-r border-violet-100 dark:border-violet-800 dark:bg-violet-900/20 dark:text-violet-400">
                      {currentPieceIndex + 1}/{albumData.pieces.length}
                    </div>
                    <button
                      onClick={handleNext}
                      disabled={currentPieceIndex === albumData.pieces.length - 1}
                      className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-r-lg hover:bg-violet-100 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <AddNewPiece
                    album_name={albumData ? albumData.name : ""}
                    album_id={id}
                    button_view={<NewMusicCard />}
                  />
                  <button
                    onClick={() => setMix((prev) => prev + 1)}
                    className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    <span className="whitespace-nowrap">Mix Pieces</span>
                  </button>
                  <button
                    onClick={() => setIsReordering(!isReordering)}
                    className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 
                      ${isReordering
                        ? 'text-red-600 bg-red-50 hover:bg-red-100 dark:bg-red-900/20 dark:text-red-400 dark:hover:bg-red-900/30'
                        : 'text-violet-600 bg-violet-50 hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30'
                      }`}
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    <span className="whitespace-nowrap">{isReordering ? 'Done' : 'Reorder'}</span>
                  </button>
                </>
              )}
              <button
                onClick={() => setMode(mode === 'play' ? 'view' : 'play')}
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${mode === 'play'
                  ? 'text-red-600 bg-red-50 hover:bg-red-100 dark:bg-red-900/20 dark:text-red-400 dark:hover:bg-red-900/30'
                  : 'text-violet-600 bg-violet-50 hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30'
                  }`}
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  {mode === 'play' ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0m-6 0a3 3 0 006 0m-6 0a3 3 0 016 0m-3-3a3 3 0 00-3 3h6a3 3 0 00-3-3m0 0V9m0 0V6m0 3h.01M9 3h.01M15 3h.01M21 3h.01M21 9h.01M21 15h.01M21 21h.01M15 21h.01M9 21h.01M3 21h.01M3 15h.01M3 9h.01M3 3h.01" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  )}
                </svg>
                <span className="whitespace-nowrap">{mode === 'play' ? 'View' : 'Play'}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {mode === 'play' ? (
        <div className="p-8">
          {albumData?.pieces?.length > 0 ? (
            <div className="space-y-6">
              <Piece piece={albumData.pieces[currentPieceIndex]} sightreading={true} />
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500">No pieces in this album</p>
            </div>
          )}
        </div>
      ) : (
        <div className="p-4">
          {pieces.length > 0 ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={pieces.map(p => p.album_piece_id)}
                strategy={rectSortingStrategy}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                  {pieces.map((piece, index) => (
                    <SortablePieceCard
                      key={piece.album_piece_id}
                      piece={piece}
                      index={index}
                      isReordering={isReordering}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          ) : (
            <div className="flex flex-col items-center justify-center h-64 text-center">
              <svg className="w-12 h-12 text-gray-400 dark:text-gray-600 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
              </svg>
              <p className="text-lg font-medium text-gray-400 dark:text-gray-600">No pieces in this album yet</p>
              <p className="text-sm text-gray-400 dark:text-gray-600">Add pieces to get started</p>
            </div>
          )}
        </div>
      )}

      <EditAlbumModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        album={albumData}
        onSubmit={handleUpdateAlbum}
        isLoading={isUpdating}
      />
    </div>
  );
}

export default Album