import requestPiece from '../../api/requestPiece';
import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import { useContext, useEffect, useState } from 'react';

function RequestPiece(props) {

  const [instrument, setInstrument] = useState(null)
  const [composer, setComposer] = useState(null)
  const [pieceName, setPieceName] = useState(null);
  const [status, setStatus] = useState('neutral')

  const { user } = useContext(AccountContext);

  const submit = () => {
    requestPiece(user, instrument, composer, pieceName).then(() => {
      setInstrument(null);
      setComposer(null);
      setPieceName(null);
      setStatus('success')
      window.location.reload()
    }).catch((err) => {
      setStatus('failed')
      window.location.reload()
    })
  }

  return (
    <Modal title="Request a piece" button_text="Request piece" submit={submit} button_view={
      <button
        className="text-nowrap whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
      >
        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
        </svg>
        Request a piece
      </button>
    }>
      <div className="w-full mb-2">
        <label for="last_name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Instrument</label>
        <select id="instruments"
          onChange={(e) => setInstrument(e.target.value)}
          value={instrument}
          className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
          <option selected value="-">Select an instrument</option>
          <option value="piano">Piano</option>
          {/*<option value="violin">Violin</option>
          <option value="cello">Cello</option>*/}
        </select>
      </div>
      <div className="w-full mb-2">
        <label for="first_name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Composer</label>
        <input type="text" id="first_name"
          onChange={(e) => setComposer(e.target.value)}
          value={composer}
          className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          placeholder="Wolfgang Amadeus Mozart" required />
      </div>
      <div className="w-full mb-2">
        <label for="first_name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Piece name</label>
        <input type="text" id="first_name"
          onChange={(e) => setPieceName(e.target.value)}
          value={pieceName}
          className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          placeholder="Wolfgang Amadeus Mozart" required />
      </div>
    </Modal>
  )
}

export default RequestPiece