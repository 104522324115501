import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../stores/Account';
import searchMusic from '../../api/searchMusic';

const QueryPiece = ({ instrument, placeholder = "Search pieces...", showSearch = true, selectedPiece, setSelectedPiece, type, order_by, difficulty, time_period, key_signature, composer, offset, ptype, piece_id_to_match, match_time_period, match_pitch, match_rhythm, match_key_sig, match_composer, tags }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { user } = useContext(AccountContext);

  useEffect(() => {
    if (selectedPiece) {
      setSearchTerm(selectedPiece.title);
    }
  }, [selectedPiece]);

  useEffect(() => {
    const fetchResults = async () => {
      if (searchTerm && !selectedPiece) {
        setLoading(true);
        try {
          const data = await searchMusic(user, searchTerm, type, order_by, instrument, difficulty, time_period, key_signature, composer, offset, ptype, piece_id_to_match, match_time_period, match_pitch, match_rhythm, match_key_sig, match_composer, tags);
          setResults(data.pieces || []);
          console.log(data.pieces);
        } catch (err) {
          console.error('Error fetching music:', err);
          setResults([]);
        } finally {
          setLoading(false);
        }
      } else {
        setResults([]);
      }
    };

    const debounceTimer = setTimeout(fetchResults, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, user, instrument, selectedPiece]);

  const handlePieceSelect = (piece) => {
    setSelectedPiece(piece);
    setShowDropdown(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setSelectedPiece(null);
    setShowDropdown(true);
  };

  return (
    <div className="relative w-full max-w-md">
      <div className="relative">
        {showSearch && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => !selectedPiece && setShowDropdown(true)}
          className={`w-full ${showSearch ? 'pl-10' : 'pl-4'} px-4 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500`}
          placeholder={placeholder}
        />
        {loading && (
          <div className="absolute right-3 top-2.5">
            <div className="w-5 h-5 border-t-2 border-violet-500 border-solid rounded-full animate-spin"></div>
          </div>
        )}
      </div>

      {showDropdown && searchTerm && !selectedPiece && (
        <div
          className="absolute z-50 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg max-h-96 overflow-y-auto"
          style={{ minHeight: results.length === 0 ? '100px' : 'auto' }}
        >
          {results.length > 0 ? (
            <ul className="py-2">
              {results.map((piece) => (
                <li
                  key={piece.piece_id}
                  onClick={() => handlePieceSelect(piece)}
                  className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-150"
                >
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      {piece.title}
                    </span>
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      {`${piece.composer_fname} ${piece.composer_lname}`}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex items-center justify-center h-full p-4">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {loading ? 'Searching...' : 'No pieces found'}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QueryPiece;