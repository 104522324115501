import { useState, useContext, useRef, useEffect } from 'react';
import Modal from '../../components/Modal';
import { AccountContext } from '../../stores/Account';
import { Label } from '../../components/Input';
import updateAlbum from '../../api/updateAlbum';
import ConcertHall from "../../assets/stock/concerthall.png";

function EditAlbumModal({ isOpen, onClose, album, onSubmit, isLoading }) {
  const { user } = useContext(AccountContext);
  const [name, setName] = useState(album?.name || '');
  const [selectedImage, setSelectedImage] = useState(album?.image || '');
  const fileInputRef = useRef(null);

  useEffect(() => {
    setName(album?.name || '');
    setSelectedImage(album?.picture || '');
  }, [album]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(name, selectedImage);
  };

  return (
    <Modal
      button_view={<div />}
      title={<span className="whitespace-nowrap">Edit Album</span>}
      button_text={<span className="whitespace-nowrap">Save Changes</span>}
      submit={handleSubmit}
      fullPageOpen={isOpen}
      onModalClose={onClose}
      isLoading={isLoading}
    >
      <div className="space-y-6">
        <div>
          <Label label="Album Name" />
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="My Classical Favorites"
          />
        </div>

        <div>
          <Label label="Album Cover" />
          <div className="mt-2 flex items-center gap-x-6">
            <img
              src={selectedImage}
              alt="Album cover"
              className="h-24 w-24 rounded-lg object-cover"
            />
            <div className="flex flex-col gap-y-2">
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
              >
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span className="whitespace-nowrap">Change Image</span>
              </button>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Recommended: 400x400px or larger
              </p>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              accept="image/*"
              className="hidden"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditAlbumModal; 