import API from ".";

const viewDrill = (user, drill_id) => {

  let obj = {
    "drill_id": drill_id,
  }

  return new API(user).postRequest('/drill/view', obj);
}

export default viewDrill;