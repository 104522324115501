import API from ".";

const getTags = (user) => {
  let url = '/piece/alltags';

  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getTags;