import API from ".";

const practiceTable = (user, student_id) => {
  let url = '/practice/table';

  if (student_id) {
    url += '?ts_flag=1&ts_stud_id=' + student_id;
  }

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default practiceTable;