import API from ".";

const reorderAlbumPieces = (user, album, ordering) => {

  let obj = {
    "album_id": album,
    "ordering": ordering.join(',')
  }

  return new API(user).postRequest('/album/order', obj);
}

export default reorderAlbumPieces;