import API from ".";

const requestPiece = (user, instrument, composer, name) => {

  let obj = {
    "instrument": instrument,
    "composer": composer,
    "piece_name": name
  }

  return new API(user).postRequest('/piece/request', obj);
}

export default requestPiece;