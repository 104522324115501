import { useContext } from "react";
import { SystemContext } from "../../stores/System";

function FAQ() {

  const { darkMode } = useContext(SystemContext);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-100 dark:border-gray-700">
          <div className="max-w-3xl mx-auto text-center pb-11">
            <h2 className="h2 mb-4 text-gray-900 dark:text-white">Frequently Asked Questions.</h2>
            <p className="text-xl text-gray-400">You probably have some more questions about Subito and how it can help you. Here are our responses to some of the most common ones we get.</p>
          </div>
          <div className={`grid divide-y divide-gray-200 w-9/12 mx-auto`}>
            <div className="py-5">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                  <p className="text-lg text-gray-900 dark:text-white mb-4">What does Subito mean?</p>
                  <span className="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke={darkMode ? `white` : `black`} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <span className="text-md text-gray-400 -mb-2">Subito is an Italian word that means immediately, or suddenly, and is often used as a direction in music. We chose the name Subito because we want to help you learn and improve faster than ever before. We hope that you will begin seeing results in the efficiency or quality of your practice and your motivation to continue to practice immediately after beginning to use our product.</span>
              </details>
            </div>
          </div>
          <div className={`grid divide-y divide-gray-200 w-9/12 mx-auto`}>
            <div className="py-5">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                  <p className="text-lg text-gray-900 dark:text-white mb-4">Who are the creators of Subito?</p>
                  <span className="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke={darkMode ? `white` : `black`} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <span className="text-md text-gray-400 -mb-2">Subito was created by fellow musicians with the goal of addressing some of the most common challenges of learning a new instrument and maintaining effective practice methods. We designed Subito with the knowledge that only our combined 20+ years of cello and piano practice woes can provide.</span>
              </details>
            </div>
          </div>
          <div className={`grid divide-y divide-gray-200 w-9/12 mx-auto`}>
            <div className="py-5">
              <details className="group">

                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                  <p className="text-lg text-gray-900 dark:text-white mb-4">Why are music drills so important?</p>
                  <span className="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke={darkMode ? `white` : `black`} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <span className="text-md text-gray-400 -mb-2">The most fun part of learning an instrument is undoubtedly playing. But repeatedly playing a piece over and over is not a recipe for efficient practice success. We created Subito with the intention of making practice as efficient and structured as ever before. This is why we created our drills feature to automatically generate playing drills designed to help you immediately master short sections of a given piece, rapidly expediting frustrating practice and allowing you to start playing pieces beautifully, from the start.</span>
              </details>
            </div>
          </div>
          <div className={`grid divide-y divide-gray-200 w-9/12 mx-auto`}>
            <div className="py-5">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                  <p className="text-lg text-gray-900 dark:text-white mb-4">What are Subito’s future plans</p>
                  <span className="transition group-open:rotate-180">
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke={darkMode ? `white` : `black`} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <span className="text-md text-gray-400 -mb-2">Subito is currently in our beta launch phase with limited product features and a limited music repository. Our goal is to build the ultimate practice management tool for students and teachers and even ensembles. We plan to launch features to allow teachers and conductors to monitor students’ practice and issue playing assignments via the platform, curating custom music and drills. We will pair this facet of the product with further enhanced drills and gamification throughout the platform to make practice more engaging and fun than ever before! We hope to continue to cultivate your love of learning music in a way that will maximize your full potential. All components of our product will aim to contribute directly to that mission. </span>
              </details>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ;