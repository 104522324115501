import API from ".";

const saveTeacherInfo = (user, teacherData) => {
  const obj = {
    business_name: teacherData.business_name,
    business_street_addr: teacherData.business_street_addr,
    business_city: teacherData.business_city,
    business_state: teacherData.business_state,
    business_country: teacherData.business_country,
    business_zipcode: teacherData.business_zipcode,
    studio_size: parseInt(teacherData.studio_size),
    hourly_rate: parseFloat(teacherData.hourly_rate),
    lesson_types: teacherData.lesson_types,
    subito_use: teacherData.subito_use,
    access_code: ""
  };

  return new API(user).postRequest('/teacher/update', obj);
};

export default saveTeacherInfo;