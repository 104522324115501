import API from ".";

const piecePracticeLog = (user, piece_id, period_length) => {
  let url = '/practice/piece?period_length=' + period_length + '&piece_id=' + piece_id;

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default piecePracticeLog;