import API from ".";

const uploadPiece = (user, piece_id, instrument, composerfname, composerlname, timePeriod, pieceName, difficulty, key_signature, file_type, is_private) => {

  let obj = {
    "piece_id": piece_id ?? '',
    "instrument": instrument ?? '',
    "composer_fname": composerfname ?? '',
    "composer_lname": composerlname ?? '',
    "period": timePeriod ?? '',
    "title": pieceName ?? '',
    "difficulty": difficulty ?? '',
    "key_signature": key_signature ?? '',
    "file_type": file_type ?? '',
    "private": is_private ? 1 : 0
  }
  console.log(obj);
  console.log(file_type);
  return new API(user).postRequest('/piece/upload', obj);
}

export default uploadPiece;