import API from ".";

const teacherStudioSummary = (user) => {
  let url = '/teacher/summary';

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default teacherStudioSummary;