import API from ".";

const starPiece = (user, id) => {

  let obj = {
    "piece_id": id,
  }

  return new API(user).postRequest('/piece/star', obj);
}

export default starPiece;