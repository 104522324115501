import API from ".";

const updatePracticeNotes = (user, piece_id, notes) => {

  let obj = {
    "piece_id": piece_id,
    "notes": notes
  }

  console.log(obj)
  return new API(user).postRequest('/piece/practicenotes', obj);
}

export default updatePracticeNotes;