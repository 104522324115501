import API from ".";

const listStudents = (user, offset) => {
  let url = '/teacher/liststudents?offset=' + offset;

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default listStudents;