import API from ".";

const createDrill = (user, name, piece_id, creation_json) => {

  let obj = {
    "piece_id": piece_id ? piece_id : "-",
    "drill_name": name,
    "creation_json": creation_json
  }

  return new API(user).postRequest('/drill/create', obj);
}

export default createDrill;   