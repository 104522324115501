import API from ".";

const practiceTimeChart = (user, period_length, student_id) => {
  let url = '/practice/daily?period_length=' + period_length;

  if (student_id) {
    url += '&ts_flag=1&ts_stud_id=' + student_id;
  }

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default practiceTimeChart;