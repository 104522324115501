import { daysSinceDate } from "../../utils/datetime";
import deleteAlbum from "../../api/deleteAlbum";
import { useContext } from "react";
import { AccountContext } from "../../stores/Account";
import ConcertHall from "../../assets/stock/concerthall.png"
import Piano from "../../assets/stock/piano.png"
import SheetMusic from "../../assets/stock/sheetmusic.png"
import getAlbumData from "../../api/getAlbumData";

const ListCard = (props) => {
  const { user } = useContext(AccountContext);

  const handlePlay = async (e) => {
    e.preventDefault();
    try {
      window.location.href = `/dashboard/music/album/${props.id}?mode=play`;
    } catch (error) {
      console.error('Error fetching album details:', error);
    }
  };

  const handleDeleteAlbum = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteAlbum(user, props.id)
      .then(() => window.location.reload())
      .catch(err => console.log(err));
  }

  return (
    <a
      href={"/dashboard/music/album/" + props.id}
      className="group w-full sm:w-64 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
    >
      <div className="relative">
        <img
          className="w-full h-40 object-cover rounded-t-lg"
          src={props.picture ? props.picture : props.index % 3 === 0 ? Piano : props.index % 3 === 1 ? SheetMusic : ConcertHall}
          alt={props.name}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-t-lg" />
      </div>

      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white line-clamp-1">
          {props.name}
        </h3>

        <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
          {daysSinceDate(props.last_updated) > 0
            ? `Updated ${daysSinceDate(props.last_updated)}d ago`
            : "Updated today"
          }
        </p>

        <div className="mt-4 flex gap-2">
          <button
            onClick={handlePlay}
            className="flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
          >
            <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 3l14 9-14 9V3z" />
            </svg>
            <span className="whitespace-nowrap">Play</span>
          </button>

          <button
            onClick={handleDeleteAlbum}
            className="flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-600 bg-gray-50 rounded-lg hover:bg-gray-100 hover:text-red-600 dark:bg-gray-700/50 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-red-400 transition-all duration-200"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>
    </a>
  );
};

export default ListCard;