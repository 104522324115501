import API from ".";

const addPieceToAlbum = (user, album, piece) => {

  let obj = {
    "piece_id": piece,
    "album_id": album,
    "name": ""
  }

  return new API(user).postRequest('/album/addpiece', obj);
}

export default addPieceToAlbum;