import flagPiece from '../../api/flagPiece';
import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import React, { useContext, useState } from 'react';
import { SystemContext } from '../../stores/System';

function FlagPiece(props) {

  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState('neutral')

  const { user } = useContext(AccountContext);

  const { setSeenKeyboardShortcuts } = useContext(SystemContext);

  const submit = () => {
    flagPiece(user, props.id, message).then(() => {
      setStatus('success')
      setMessage(null);
      window.location.reload()
    }).catch((err) => {
      setStatus('failed')
      console.log(err);
      window.location.reload()
    })
  }

  return (
    <div className="w-full flex justify-end items-center space-x-2">
      <Modal title="Report issue" button_text="Report issue" submit={submit} button_view={
        <button className="p-2 text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-all duration-200">
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
            />
          </svg>
        </button>
      }>
        <div className="w-full mb-2">
          <label for="message"
            className="block mb-2 text-sm font-medium dark:text-white">What is wrong with the piece?</label>
          <textarea id="message" rows="4"
            onChange={(e) => setMessage(e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={message}
            placeholder="Please explain the issue with this piece. Thanks!"></textarea>
        </div>
      </Modal>
      <button
        onClick={() => setSeenKeyboardShortcuts(false)}
        className="p-2 text-gray-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-all duration-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>
    </div>
  )
}

export default FlagPiece