import FlatPNGEmbed from './FlatPNGEmbed';

function DrillsCard({ drill }) {
    //   const { user } = useContext(AccountContext);

    //   const view = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     e.nativeEvent.stopImmediatePropagation();
    //     viewPiece(user, piece.id)
    //   }

    const formatCreatedDate = (dateString) => {
        if (!dateString) return '';
        const [datePart] = dateString.split(' '); // Split at space and take first part
        const [month, day, year] = datePart.split('/');
        return `${month}/${day}/${year}`;
    };

    console.log(drill);
    return (
        <div
            onClick={() => window.location.href = `/dashboard/exercises/${drill.id}`}
            className="group relative bg-white dark:bg-gray-800 rounded-xl shadow-md hover:shadow-lg dark:hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300"
            style={{ width: '220px', height: '400px' }}
        >
            {/* Preview Section */}
            <div className="relative w-full h-[240px] bg-gray-50 dark:bg-gray-900">
                <div className="overflow-hidden rounded-t-xl">
                    <div className="absolute inset-0">
                        <FlatPNGEmbed
                            sharingKey={drill.flat_sharing_key}
                            scoreId={drill.flat_score_id}
                            height={240}
                        />
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="p-4 flex flex-col justify-between h-[160px]">
                <div className="flex items-start justify-between gap-2">
                    <div className="flex-1">
                        <h3 className="font-bold text-gray-900 dark:text-white line-clamp-2 leading-tight mb-1">
                            {drill.drill_name || "Drill"}
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                            {drill.piece_title || "No affiliated piece"}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                            Created {formatCreatedDate(drill.created_time)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DrillsCard;