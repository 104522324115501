import API from ".";

const viewPiece = (user, id) => {

  let obj = {
    "piece_id": id,
  }

  return new API(user).postRequest('/piece/view', obj);
}

export default viewPiece;