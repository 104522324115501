export function daysSinceDate(dateString: string): number {
  if (!dateString || dateString === null || dateString === undefined) return 0;

  // Parse the input date string
  const [datePart, timePart] = dateString.split(' ');
  const [month, day, year] = datePart.split('/').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Create Date objects for the input date and today
  const inputDate = new Date(2000 + year, month - 1, day, hours, minutes, seconds);
  const today = new Date();

  // Calculate the difference in milliseconds
  const differenceMs = today.getTime() - inputDate.getTime();

  // Convert milliseconds to days and round down
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return days;
}

export function getCurrentTimeFormatted(date: Date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
