import API from ".";

const practiceSummary = (user, period_length) => {
  let url = '/practice/summary?period_length=' + period_length;

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default practiceSummary;