import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../stores/Account';
import { useSearchParams } from 'react-router-dom';
import useStateParam from '../../hooks/useStateParam';
import DrillsCard from '../components/DrillsCard';
import QueryPiece from '../components/QueryPiece';
import CreateDrill from '../components/CreateDrill';
import Pagination from '../components/Pagination';
import listDrills from '../../api/listDrills';
import { Label } from '../../components/Input';

function Exercises() {
    const { user } = useContext(AccountContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [drills, setDrills] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [piece, setPiece] = useState(() => {
        const id = searchParams.get('piece_id');
        const name = searchParams.get('piece_name');
        return id && name ? { id, name } : null;
    });
    const [searchWord, setSearchWord] = useStateParam('search', undefined);
    const [page, setPage] = useStateParam('page', "int", 1);
    const [firstRender, setFirstRender] = useState(true);

    const resultsPerPage = 10;

    useEffect(() => {
        const newParams = new URLSearchParams(searchParams.toString());
        if (piece) {
            newParams.set('piece_id', piece.id);
            newParams.set('piece_name', piece.name);
        } else {
            newParams.delete('piece_id');
            newParams.delete('piece_name');
        }
        setSearchParams(newParams, { replace: true });
    }, [piece]);

    const fetchDrills = async (page) => {
        setIsLoading(true);
        try {
            const offset = (page - 1) * resultsPerPage;
            const drillsData = await listDrills(
                user,
                piece?.id || "-",
                searchWord || "-",
                offset
            );
            setDrills(drillsData.drills || []);
            setTotalResults(drillsData.count || 0);
            setFirstRender(false);
        } catch (error) {
            console.error('Error fetching drills:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchWord !== undefined && searchWord !== null) {
                setDrills([]);
                setPage(1);
                fetchDrills(1);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchWord]);

    const handlePieceSelect = (piece) => {
        if (piece) {
            setPiece({ id: piece.id, name: piece.title });
        } else {
            setPiece(null);
        }
    };

    useEffect(() => {
        setDrills([]);
        setPage(1);
        fetchDrills(1);
    }, [piece]);

    function onPageChange(page) {
        if (!firstRender) {
            setPage(page);
            setDrills([]);
            fetchDrills(page);
        }
        console.log("Calling on page change");
    };

    return (
        <div className="w-full overflow-hidden no-scrollbar">
            <div className="box-border overflow-x-hidden shadow-md w-full rounded-lg dark:bg-gray-700 bg-white overflow-hidden no-scrollbar">
                <div className="p-6">
                    {/* Search and Filter Section */}
                    <div className="flex flex-col sm:flex-row gap-2 mb-8">
                        <div className="flex-1 flex gap-2">
                            <div className="flex-1">
                                <input
                                    type="text"
                                    value={searchWord}
                                    onChange={(e) => setSearchWord(e.target.value)}
                                    className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="Search exercises by name..."
                                />
                            </div>
                            <div className="flex-1">
                                <QueryPiece
                                    placeholder="Filter by piece..."
                                    showSearch={false}
                                    instrument="piano"
                                    setSelectedPiece={handlePieceSelect}
                                    selectedPiece={piece ? { id: piece.id, title: piece.name } : null}
                                />
                            </div>
                        </div>
                        <CreateDrill />
                    </div>

                    {/* Results Section */}
                    {isLoading ? (
                        <div className="flex justify-center items-center py-12">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
                        </div>
                    ) : drills.length > 0 ? (
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-center sm:justify-start lg:ml-4 flex-wrap gap-1 gap-y-4 overflow-y-scroll no-scrollbar">
                                {drills.map((drill) => (
                                    <DrillsCard key={drill.id} drill={drill} />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="text-center py-12">
                            <svg className="w-16 h-16 text-gray-400 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                            </svg>
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                                No exercises found
                            </h3>
                            <p className="text-gray-500 dark:text-gray-400 text-center max-w-md mx-auto">
                                {searchWord || piece
                                    ? "No exercises match your search criteria. Try adjusting your filters."
                                    : "Create your first exercise to get started!"}
                            </p>
                        </div>
                    )}
                    <div className="pt-4">
                        <Pagination
                            totalResults={totalResults}
                            resultsPerPage={resultsPerPage}
                            onChange={onPageChange}
                            label="Table navigation"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Exercises;
