import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import { useContext, useState } from 'react';
import { Label } from '../../components/Input';
import createAlbum from '../../api/createAlbum';

function CreateList(props) {

  const [listName, setListName] = useState(null);

  const { user } = useContext(AccountContext);

  const submit = () => {
    if (listName && listName !== null && listName !== "") {
      createAlbum(user, listName).then((res) => {
        window.location.reload()
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  return (
    <Modal title="Create a new list" button_text="Create list" submit={submit} button_view={props.button_view}>
      <div
        className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
        <div className="w-full">
          <Label label="List name" />
          <input type="text" id="list_name"
            onChange={(e) => setListName(e.target.value)}
            value={listName}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="My classical favorites" required />
        </div>
      </div>
    </Modal>
  )
}

export default CreateList