import { useState } from 'react';
import Modal from '../../components/Modal';
import { Label } from '../../components/Input';
import registerTeacher from '../../api/registerTeacher';
import { AccountContext } from '../../stores/Account';
import { useContext } from 'react';

function TeacherRegistrationModal({ button_view, setDisabled }) {
  const [formData, setFormData] = useState({
    business_name: '',
    business_street_addr: '',
    business_city: '',
    business_state: '',
    business_country: '',
    business_zipcode: '',
    studio_size: '',
    hourly_rate: '',
    lesson_types: '',
    subito_use: '',
    access_code: ''
  });
  const { user } = useContext(AccountContext);

  const submit = async () => {
    try {
      await registerTeacher(user, formData);
      window.location.reload();
    } catch (err) {
      console.error('Failed to register as teacher:', err);
      // You might want to add error handling/display here
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Modal
      title="Teacher Registration"
      button_text="Register"
      submit={submit}
      onModalClose={() => setDisabled?.(false)}
      onModalOpen={() => setDisabled?.(true)}
      button_view={button_view}
    >
      <div className="space-y-4">
        <div>
          <Label label="Business Name" />
          <input
            type="text"
            name="business_name"
            value={formData.business_name}
            onChange={handleChange}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Enter your business name"
            required
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Label label="Street Address" />
            <input
              type="text"
              name="business_street_addr"
              value={formData.business_street_addr}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter street address"
            />
          </div>
          <div>
            <Label label="City" />
            <input
              type="text"
              name="business_city"
              value={formData.business_city}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter city"
            />
          </div>
          <div>
            <Label label="State" />
            <input
              type="text"
              name="business_state"
              value={formData.business_state}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter state"
            />
          </div>
          <div>
            <Label label="Country" />
            <input
              type="text"
              name="business_country"
              value={formData.business_country}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter country"
            />
          </div>
          <div>
            <Label label="ZIP Code" />
            <input
              type="text"
              name="business_zipcode"
              value={formData.business_zipcode}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter ZIP code"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Label label="Studio Size (number of students)" />
            <input
              type="number"
              name="studio_size"
              value={formData.studio_size}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter number of students"
            />
          </div>
          <div>
            <Label label="Hourly Rate ($)" />
            <input
              type="number"
              name="hourly_rate"
              value={formData.hourly_rate}
              onChange={handleChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter hourly rate"
            />
          </div>
        </div>

        <div>
          <Label label="Lesson Types" />
          <select
            name="lesson_types"
            value={formData.lesson_types}
            onChange={handleChange}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          >
            <option value="">Select lesson type</option>
            <option value="in_person">In Person Only</option>
            <option value="online">Online Only</option>
            <option value="hybrid">Hybrid (Both)</option>
          </select>
        </div>

        <div>
          <Label label="How will you use Subito?" />
          <textarea
            name="subito_use"
            value={formData.subito_use}
            onChange={handleChange}
            rows="3"
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Tell us how you plan to use Subito in your teaching practice..."
          />
        </div>

        <div>
          <Label label="Access Code" />
          <input
            type="text"
            name="access_code"
            value={formData.access_code}
            onChange={handleChange}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Enter your access code"
            required
          />
        </div>
      </div>
    </Modal>
  );
}

export default TeacherRegistrationModal;
