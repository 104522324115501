import React, { useState, useRef, useEffect, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import practiceTimeChart from '../../api/practiceTimeChart';
import { AccountContext } from '../../stores/Account';
const timeRanges = [
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 3 months', value: 90 },
    { label: 'Last year', value: 365 },
];

const WeeklyPractice = ({ timeRange = 7, student_id }) => {

    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [totalPractice, setTotalPractice] = useState(0);

    const { user } = useContext(AccountContext);
    useEffect(() => {
        practiceTimeChart(user, timeRange, student_id).then((dat) => {
            console.log(dat);
            setCategories(Object.keys(dat));
            setData(Object.values(dat));
            setTotalPractice(Object.values(dat).reduce((acc, curr) => acc + curr, 0));
        });
    }, [timeRange, student_id]);

    console.log(categories, data);

    const options = {
        chart: {
            height: "100%",
            type: "area",
            fontFamily: "Inter, sans-serif",
            dropShadow: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.55,
                opacityTo: 0,
                shade: "#6C48EF",
                gradientToColors: ["#6C48EF"],
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 6,
        },
        grid: {
            show: false,
            strokeDashArray: 4,
            padding: {
                left: 2,
                right: 2,
                top: 0
            },
        },
        series: [
            {
                name: "Minutes of Practice",
                data: data,
                color: "#6C48EF",
            },
        ],
        xaxis: {
            categories: categories,
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: false,
        },
    }

    return (
        <div className="w-full">
            <div className="flex flex-start mb-3">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Practice Time
                </h3>
            </div>
            <div className="w-full relative">
                <div className="flex justify-between">
                    <div>
                        <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">{totalPractice} mins</h5>
                        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Practice in {timeRanges.find(r => r.value === timeRange)?.label.toLowerCase() || 'the last 7 days'}
                        </p>
                    </div>
                </div>
                <ReactApexChart options={options} series={options.series} type="area" height={350} />
            </div>
        </div>
    )
}

export default WeeklyPractice;
