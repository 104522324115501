// import { useEffect, useRef, useState, useContext } from 'react';
// import Embed from 'flat-embed';

// function FlatPNGEmbed(props) {
//   const containerRef = useRef(null);
//   const [png, setPng] = useState(null);
//   const [error, setError] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const embedRef = useRef(null);

//   useEffect(() => {
//     setPng(null);
//     setError(null);
//     setIsLoading(true);

//     if (containerRef.current) {
//       try {
//         embedRef.current = new Embed(containerRef.current, {
//           width: '100%',
//           height: parseInt(props.height),
//           lazy: true,
//           score: props.scoreId,
//           embedParams: {
//             layout: 'page',
//             zoom: 'auto',
//             themeScoreBackground: 'transparent',
//             branding: false,
//             noHeader: true,
//             controlsDisplay: false,
//             drawOnlyFirstPage: true,
//             controlsPosition: 'top',
//             controlsPrint: false,
//             appId: '667c53d0e181f889c06e5f62',
//             sharingKey: props.sharingKey
//           }
//         });

//         embedRef.current
//           .getPNG({
//             result: 'dataURL',
//             layout: 'page',
//             dpi: 100,
//           })
//           .then(function (res) {
//             setPng(res);
//             setIsLoading(false);
//           })
//           .catch((err) => {
//             console.error('Error getting PNG:', err);
//             setError(err);
//             setIsLoading(false);
//           });
//       } catch (err) {
//         console.error('Error creating embed:', err);
//         setError(err);
//         setIsLoading(false);
//       }
//     }

//     return () => {
//       if (embedRef.current) {
//         embedRef.current = null;
//       }
//     };
//   }, [props.scoreId, props.sharingKey]);

//   return <>
//     <div
//       ref={containerRef}
//       className="cursor-pointer select-none"
//       style={{
//         width: 0, height: 0
//       }}
//     ></div>
//     {isLoading && !png && (
//       <div className="w-full h-full flex items-center justify-center bg-white">
//         <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
//       </div>
//     )}
//     {error && (
//       <div className="w-full h-full flex items-center justify-center bg-white text-red-500">
//         Failed to load score preview
//       </div>
//     )}
//     {png && (
//       <img src={png} className="w-full h-full object-contain bg-white" />
//     )}
//   </>;
// }

// export default FlatPNGEmbed;
// import { useEffect, useRef, useState, useContext } from 'react';
// import Embed from 'flat-embed';

// function FlatPNGEmbed(props) {
//   const containerRef = useRef(null);

//   const [png, setPng] = useState(null);

//   useEffect(() => {
//     setPng(null);
//     if (containerRef.current) {
//       try {
//         const embed = new Embed(containerRef.current, {
//           width: '100%',
//           height: parseInt(props.height),
//           lazy: true,
//           score: props.scoreId,
//           embedParams: {
//             drawOnlyFirstPage: true,
//             layout: 'page',
//             zoom: 'auto',
//             themeScoreBackground: 'transparent',
//             branding: false,
//             noHeader: true,
//             controlsDisplay: false,
//             controlsPosition: 'top',
//             controlsPrint: false,
//             appId: '667c53d0e181f889c06e5f62',
//             sharingKey: props.sharingKey
//           }
//         });

//         embed
//           .getPNG({
//             result: 'dataURL',
//             layout: 'page',
//             dpi: 100,
//           })
//           .then(function (res) {
//             // 300 DPI PNG with the score as a page, returned as a DataURL
//             setPng(res);
//           });
//       } catch (err) {
//         console.error('Error creating embed:', err);
//       }
//     }
//   }, [props.scoreId, props.sharingKey]);

//   return <>
//     <div
//       ref={containerRef}
//       className="cursor-pointer select-none"
//       style={{
//         width: 0, height: 0
//       }}
//     ></div>
//     <img src={png} className="w-full h-full object-contain bg-white" />
//   </>;
// }

// export default FlatPNGEmbed;
import { useEffect, useRef, useState } from 'react';
import Embed from 'flat-embed';

function FlatPNGEmbed(props) {
  const containerRef = useRef(null);
  const [png, setPng] = useState(null);

  useEffect(() => {
    setPng(null);
    if (containerRef.current) {
      try {
        const embed = new Embed(containerRef.current, {
          width: '100%',
          height: parseInt(props.height),
          lazy: true,
          score: props.scoreId,
          embedParams: {
            drawOnlyFirstPage: true,
            layout: 'page',
            zoom: 'auto',
            themeScoreBackground: 'transparent',
            branding: false,
            noHeader: true,
            displayFirstLinePartsNames: false,
            controlsDisplay: false,
            controlsPosition: 'top',
            controlsPrint: false,
            appId: '667c53d0e181f889c06e5f62',
            sharingKey: props.sharingKey,
          },
        });

        // Add an iframe load event listener if possible
        const iframe = containerRef.current.querySelector('iframe');
        if (iframe) {
          iframe.onload = () => {
            embed
              .getPNG({
                result: 'dataURL',
                layout: 'page',
                dpi: 100,
              })
              .then((res) => {
                setPng(res);
              })
              .catch((err) => {
                console.error('Error getting PNG:', err);
              });
          };
        }
      } catch (err) {
        console.error('Error creating embed:', err);
      }
    }
  }, [props.scoreId, props.sharingKey]);

  return (
    <>
      <div
        ref={containerRef}
        className="cursor-pointer select-none"
        style={{
          width: 0,
          height: 0,
        }}
      ></div>
      {png && (
        <img src={png} className="w-full h-full object-contain bg-white" alt="Score preview" />
      )}
    </>
  );
}

export default FlatPNGEmbed;
