import API from ".";

const getDrill = (user, id) => {
  let url = '/drill/get?drill_id=' + id;

  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getDrill;