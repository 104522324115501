interface PieceObjectInput {
  id?: string;
  title?: string;
  composer_fname?: string;
  composer_lname?: string;
  instrument?: string;
  difficulty?: string;
  key_signature?: string;
  time_period?: string;
  history?: string;
  music?: string;
  measure_count?: number;
  starred?: boolean;
  is_old?: boolean;
  pdf_link?: string;
  flat_score_id?: string;
  flat_sharing_key?: string;
  type?: string;
  practice_notes?: string;
  tags?: string[];
  piece_notes?: string[];
}

class PieceType {
  public readonly id: string;
  public readonly title: string;
  public readonly composer_fname: string;
  public readonly composer_lname: string;
  public readonly instrument: string;
  public readonly difficulty: string;
  public readonly key_signature: string;
  public readonly time_period: string;
  public readonly history: string;
  public readonly music: string;
  public readonly measure_count: number;
  public readonly starred: boolean;
  public readonly is_old: boolean;
  public readonly pdf_link: string;
  public readonly flat_score_id: string;
  public readonly flat_sharing_key: string;
  public readonly type: string;
  public readonly practice_notes: string;
  public readonly tags: string[];
  public readonly piece_notes: string[];

  constructor(input: PieceObjectInput) {
    this.id = input.id ?? '';  // Default value: empty string
    this.title = input.title ?? '';
    this.composer_fname = input.composer_fname ?? '';
    this.composer_lname = input.composer_lname ?? '';
    this.instrument = input.instrument ?? '';
    this.difficulty = input.difficulty ?? '';
    this.key_signature = input.key_signature ?? '';
    this.time_period = input.time_period ?? '';
    this.history = input.history ?? '';
    this.music = input.music ?? '';
    this.measure_count = input.measure_count ?? 0; // Default value: 0
    this.starred = input.starred ?? false;
    this.is_old = input.is_old ?? false;
    this.pdf_link = input.pdf_link ?? '';
    this.flat_score_id = input.flat_score_id ?? '';
    this.flat_sharing_key = input.flat_sharing_key ?? '';
    this.type = input.type ?? 'none';
    this.practice_notes = input.practice_notes ?? '';
    this.tags = input.tags ?? [];
    this.piece_notes = input.piece_notes ?? [];
  }
}

export default PieceType;