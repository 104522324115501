import { AccountContext } from '../../stores/Account';
import Modal from '../../components/Modal';
import { useContext, useState } from 'react';
import QueryPiece from './QueryPiece';
import addPieceToAlbum from '../../api/addPieceToAlbum';

function AddNewPiece(props) {

  const [piece, setPiece] = useState(null);

  const { user } = useContext(AccountContext);

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (piece && piece !== null && 'id' in piece && piece.id !== "") {
      addPieceToAlbum(user, props.album_id, piece.id).then((res) => {
        window.location.reload()
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  return (
    <Modal title={`Add piece to ${props.album_name}`} button_text="Add piece" submit={submit} button_view={props.button_view}>
      <QueryPiece selectedPiece={piece} setSelectedPiece={setPiece} />
    </Modal>
  )
}

export default AddNewPiece