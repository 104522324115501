import { useState, useEffect, useContext } from 'react';
import Modal from '../../components/Modal';
import { Label } from '../../components/Input';
import QueryPiece from './QueryPiece';
import createDrill from '../../api/createDrill';
import { AccountContext } from '../../stores/Account';
import Select from 'react-select';

const rhythmOptions = [
  { value: '1', label: 'Whole (1)' },
  { value: '1/2', label: 'Half (1/2)' },
  { value: '1/2.', label: 'Dotted Half (1/2.)' },
  { value: '1/4', label: 'Quarter (1/4)' },
  { value: '1/4.', label: 'Dotted Quarter (1/4.)' },
  { value: '1/8', label: 'Eighth (1/8)' },
  { value: '1/8.', label: 'Dotted Eighth (1/8.)' },
  { value: '1/16', label: 'Sixteenth (1/16)' },
  { value: '1/32', label: 'Thirty-Second (1/32)' }
];

const pitchOptions = [
  { value: 'C', label: 'C' },
  { value: 'C#', label: 'C♯' },
  { value: 'D', label: 'D' },
  { value: 'D#', label: 'D♯' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'F#', label: 'F♯' },
  { value: 'G', label: 'G' },
  { value: 'G#', label: 'G♯' },
  { value: 'A', label: 'A' },
  { value: 'A#', label: 'A♯' },
  { value: 'B', label: 'B' }
];

const customSelectStyles = {
  control: (base) => ({
    ...base,
    borderRadius: '0.5rem',
    borderColor: 'rgb(209 213 219)',
    '&:hover': {
      borderColor: 'rgb(139 92 246)'
    }
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'rgb(245 243 255)',
    borderRadius: '9999px',
    padding: '2px 4px',
    '& > div': {
      color: 'rgb(124 58 237)'
    }
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'rgb(124 58 237)',
    ':hover': {
      backgroundColor: 'rgb(237 233 254)',
      color: 'rgb(109 40 217)'
    },
    borderRadius: '9999px'
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'rgb(139 92 246)' : base.backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected ? 'rgb(139 92 246)' : 'rgb(237 233 254)'
    }
  })
};

const VoiceRow = ({
  staffId,
  voiceIndex,
  voice,
  onDelete,
  onRhythmChange,
  onPitchPatternChange,
  onCustomPitchChange,
  onRepeatRhythmChange,
  onOctaveChange,
  interval_mapper
}) => {
  const selectedRhythms = voice.rhythmPattern.split(',')
    .map(r => r.trim())
    .filter(r => r)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: rhythmOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    }));

  const selectedPitches = voice.customPitchPattern?.split(',')
    .map(p => p.trim())
    .filter(p => p)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: pitchOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    })) || [];

  return (
    <div className="mb-4 last:mb-0">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          Voice {voiceIndex + 1}
        </h4>
        <button
          onClick={() => onDelete(staffId, voiceIndex)}
          className="p-1 text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-colors duration-200"
          title="Delete voice"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
      </div>
      <div className="space-y-3">
        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="text-xs text-gray-500 dark:text-gray-400">
              Rhythm Pattern
            </label>
          </div>
          <div className="space-y-2">
            <Select
              isMulti
              value={selectedRhythms}
              options={rhythmOptions.map(opt => ({
                ...opt,
                value: `${opt.value}-${selectedRhythms.length}`,
                actualValue: opt.value
              }))}
              onChange={(selected) => {
                const rhythmPattern = selected.map(s => s.actualValue).join(', ');
                onRhythmChange(staffId, voiceIndex, rhythmPattern);
              }}
              styles={customSelectStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select rhythm values..."
            />
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Note that across all voices the rhythm pattern must be the same beat length.
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="text-xs text-gray-500 dark:text-gray-400">
              Pitch Pattern
            </label>
          </div>
          <div className="space-y-2">
            <select
              value={voice.pitchPatternType}
              onChange={(e) => onPitchPatternChange(staffId, voiceIndex, e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="Scale">Scale</option>
              <option value="Arpeggio">Arpeggio</option>
              <option value="Interval">Interval</option>
              <option value="Custom">Custom</option>
            </select>

            {voice.pitchPatternType === 'Interval' && (
              <select
                value={voice.selectedInterval}
                onChange={(e) => onPitchPatternChange(staffId, voiceIndex, 'Interval', e.target.value)}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {Object.entries(interval_mapper).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            )}

            {voice.pitchPatternType === 'Custom' && (
              <div className="space-y-2">
                <Select
                  isMulti
                  value={selectedPitches}
                  options={pitchOptions.map(opt => ({
                    ...opt,
                    value: `${opt.value}-${selectedPitches.length}`,
                    actualValue: opt.value
                  }))}
                  onChange={(selected) => {
                    const pitchPattern = selected.map(s => s.actualValue).join(', ');
                    onCustomPitchChange(staffId, voiceIndex, pitchPattern);
                  }}
                  styles={customSelectStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select notes..."
                />
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  Select the notes in order. The accidentals will not be adjusted based on the key signature.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-1">
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Bottom Octave
            </label>
            <select
              value={voice.bottomOctave}
              onChange={(e) => onOctaveChange(staffId, voiceIndex, 'bottom', e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                <option
                  key={num}
                  value={num}
                  disabled={num > voice.topOctave}
                >
                  {num}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1">
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Top Octave
            </label>
            <select
              value={voice.topOctave}
              onChange={(e) => onOctaveChange(staffId, voiceIndex, 'top', e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                <option
                  key={num}
                  value={num}
                  disabled={num < voice.bottomOctave}
                >
                  {num}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center">
          <input
            id={`repeat-rhythm-${staffId}-${voiceIndex}`}
            type="checkbox"
            checked={voice.repeatRhythm}
            onChange={(e) => onRepeatRhythmChange(staffId, voiceIndex, e.target.checked)}
            className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor={`repeat-rhythm-${staffId}-${voiceIndex}`}
            className="ml-2 text-xs text-gray-600 dark:text-gray-400"
          >
            Repeat rhythm on each pitch?
          </label>
        </div>
      </div>
    </div>
  );
};

const ModificationRow = ({
  modification,
  index,
  onDelete,
  onTypeChange,
  onRhythmSwitchChange,
  onKeyChangeSelect,
  onTransposeIntervalChange,
  onTransposeDirectionChange,
  keySignatures,
  interval_mapper
}) => {
  const selectedOriginalRhythms = modification.originalRhythm?.split(',')
    .map(r => r.trim())
    .filter(r => r)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: rhythmOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    })) || [];

  const selectedNewRhythms = modification.newRhythm?.split(',')
    .map(r => r.trim())
    .filter(r => r)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: rhythmOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    })) || [];

  return (
    <div className="mb-4 last:mb-0">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          Modification {index + 1}
        </h4>
        <button
          onClick={() => onDelete(index)}
          type="button"
          className="p-1 text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-colors duration-200"
          title="Delete modification"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
      </div>
      <div className="space-y-3">
        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="text-xs text-gray-500 dark:text-gray-400">
              Type
            </label>
          </div>
          <select
            value={modification.type}
            onChange={(e) => onTypeChange(index, e.target.value)}
            className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="rhythm_switch">Rhythm switch</option>
            <option value="key_change">Key change</option>
            <option value="transpose">Transpose</option>
            {/*<option value="interval_switch">Interval switch</option>*/}
          </select>
        </div>

        {modification.type === 'rhythm_switch' && (
          <div className="space-y-3">
            <div>
              <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                Original Rhythm
              </label>
              <Select
                isMulti
                value={selectedOriginalRhythms}
                options={rhythmOptions.map(opt => ({
                  ...opt,
                  value: `${opt.value}-${selectedOriginalRhythms.length}`,
                  actualValue: opt.value
                }))}
                onChange={(selected) => {
                  const rhythmPattern = selected.map(s => s.actualValue).join(', ');
                  onRhythmSwitchChange(index, 'originalRhythm', rhythmPattern);
                }}
                styles={customSelectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select rhythm values..."
              />
            </div>
            <div>
              <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                New Rhythm
              </label>
              <Select
                isMulti
                value={selectedNewRhythms}
                options={rhythmOptions.map(opt => ({
                  ...opt,
                  value: `${opt.value}-${selectedNewRhythms.length}`,
                  actualValue: opt.value
                }))}
                onChange={(selected) => {
                  const rhythmPattern = selected.map(s => s.actualValue).join(', ');
                  onRhythmSwitchChange(index, 'newRhythm', rhythmPattern);
                }}
                styles={customSelectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select rhythm values..."
              />
            </div>
          </div>
        )}

        {modification.type === 'key_change' && (
          <div>
            <div className="flex items-center justify-between mb-1">
              <label className="text-xs text-gray-500 dark:text-gray-400">
                Key to Change To
              </label>
            </div>
            <select
              value={modification.targetKey}
              onChange={(e) => onKeyChangeSelect(index, e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {keySignatures.map((key) => (
                <option key={key} value={key}>
                  {key.replace('_', ' ')}
                </option>
              ))}
            </select>
          </div>
        )}

        {modification.type === 'transpose' && (
          <div className="space-y-3">
            <div>
              <div className="flex items-center justify-between mb-1">
                <label className="text-xs text-gray-500 dark:text-gray-400">
                  Interval
                </label>
              </div>
              <select
                value={modification.transposeInterval}
                onChange={(e) => onTransposeIntervalChange(index, e.target.value)}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {Object.entries(interval_mapper).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <input
                id={`transpose-direction-${index}`}
                type="checkbox"
                checked={modification.transposeDirection === 'up'}
                onChange={(e) => onTransposeDirectionChange(index, e.target.checked ? 'up' : 'down')}
                className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor={`transpose-direction-${index}`}
                className="ml-2 text-xs text-gray-600 dark:text-gray-400"
              >
                Transpose down
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function CreateDrill(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [drillName, setDrillName] = useState('');
  const [drillType, setDrillType] = useState('from_scratch');
  const [keySignature, setKeySignature] = useState('C_major');
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [excerptType, setExcerptType] = useState('full');
  const [startMeasure, setStartMeasure] = useState(1);
  const [endMeasure, setEndMeasure] = useState(1);
  const [isModificationsExpanded, setIsModificationsExpanded] = useState(true);
  const [modifications, setModifications] = useState([]);
  const [voices, setVoices] = useState({
    staff1: [],
    staff2: []
  });
  const [expandedStaffs, setExpandedStaffs] = useState({
    staff1: true,
    staff2: true
  });
  const [letAllVoicesFinish, setLetAllVoicesFinish] = useState(false);

  const MAX_VOICES_PER_STAFF = 5;

  const keySignatures = [
    'C_major', 'D_major', 'E_major', 'F_major', 'G_major', 'A_major', 'B_major', 'C#_major',
    'D#_major', 'F#_major', 'G#_major', 'A#_major', 'C_minor', 'D_minor', 'E_minor', 'F_minor',
    'G_minor', 'A_minor', 'B_minor', 'C#_minor', 'D#_minor', 'F#_minor', 'G#_minor', 'A#_minor'
  ];

  const interval_mapper = {
    'P1': 'Perfect Unison',
    'm2': 'Minor Second',
    'M2': 'Major Second',
    'm3': 'Minor Third',
    'M3': 'Major Third',
    'P4': 'Perfect Fourth',
    'A4': 'Augmented Fourth',
    'd5': 'Diminished Fifth',
    'P5': 'Perfect Fifth',
    'm6': 'Minor Sixth',
    'M6': 'Major Sixth',
    'm7': 'Minor Seventh',
    'M7': 'Major Seventh',
    'P8': 'Perfect Octave'
  };

  const toggleStaff = (staff) => {
    setExpandedStaffs(prev => ({
      ...prev,
      [staff]: !prev[staff]
    }));
  };

  useEffect(() => {
    if (props.piece) {
      setDrillName("Drill for" + props.piece.title);
      setSelectedPiece(props.piece);
      setDrillType("from_piece");
    }
  }, [props.piece])

  const { user } = useContext(AccountContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    try {
      const creationJson = build_creation_json();
      await createDrill(user, drillName, selectedPiece?.id, JSON.stringify(JSON.stringify(creationJson)).slice(1, -1));
      window.location.reload();
    } catch (error) {
      console.error('Error creating drill:', error);
    } finally {
      setIsCreating(false);
    }
  };

  const build_creation_json = () => {
    if (drillType === 'from_scratch') {
      // Convert voice data into the required format
      const convertVoices = (staffVoices) => {
        return staffVoices.map(voice => {
          const voiceObj = {
            rhythm: voice.rhythmPattern.split(',').map(r => r.trim()),
            repeat_rhythm_on_each_pitch: voice.repeatRhythm,
            octave_interval: [voice.bottomOctave, voice.topOctave]
          };

          // Handle different pitch pattern types
          if (voice.pitchPatternType === 'Scale') {
            voiceObj.pitch_pattern = 'scale';
          } else if (voice.pitchPatternType === 'Arpeggio') {
            voiceObj.pitch_pattern = 'arpeggio';
          } else if (voice.pitchPatternType === 'Interval') {
            voiceObj.pitch_pattern = 'interval';
            voiceObj.interval = voice.selectedInterval;
          } else if (voice.pitchPatternType === 'Custom') {
            voiceObj.pitch_pattern = voice.customPitchPattern.split(',').map(p => p.trim());
          }

          return voiceObj;
        });
      };

      return {
        base: 'free',
        key: keySignature,
        let_all_finish: letAllVoicesFinish,
        staffs: [
          convertVoices(voices.staff1),
          convertVoices(voices.staff2)
        ]
      };
    } else {
      // Handle from_piece case
      const json = {
        base: 'piece',
        key: keySignature,
        seq: excerptType,
        excerpt_bounds: excerptType === 'excerpt' ? [startMeasure, endMeasure] : null,
        focus: [],
        subdiv_args: { original: [], new: [] },
        subdiv_note_settings: 'repeat',  // Default to repeat
        new_key_to_change_to: '',
        transpose_dir: 1,  // Default to up
        interval_switch_args: []
      };

      // Process modifications
      modifications.forEach(mod => {
        switch (mod.type) {
          case 'rhythm_switch':
            if (!json.focus.includes('subdiv')) {
              json.focus.push('subdiv');
            }
            json.subdiv_args.original.push(mod.originalRhythm.split(',').map(r => r.trim()));
            json.subdiv_args.new.push(mod.newRhythm.split(',').map(r => r.trim()));
            break;

          case 'key_change':
            if (!json.focus.includes('change_key')) {
              json.focus.push('change_key');
            }
            json.new_key_to_change_to = mod.targetKey;
            break;

          case 'transpose':
            if (!json.focus.includes('transpose')) {
              json.focus.push('transpose');
            }
            json.transpose_dir = mod.transposeDirection === 'up' ? 1 : -1;
            json.transpose = mod.transposeInterval;
            break;

          case 'interval_switch':
            if (!json.focus.includes('switch_interval')) {
              json.focus.push('switch_interval');
            }
            // Handle interval switch args if needed
            break;
        }
      });

      // If excerpt_bounds is null, set it to indicate full piece
      if (!json.excerpt_bounds) {
        json.excerpt_bounds = [1, -1];
      }

      return json;
    }
  };

  useEffect(() => {
    console.log(build_creation_json());
  }, [voices, modifications]);

  const handleAddVoice = (staff) => {
    if (voices[staff].length >= MAX_VOICES_PER_STAFF) {
      return;
    }
    setVoices(prev => ({
      ...prev,
      [staff]: [...prev[staff], {
        rhythmPattern: '',
        pitchPattern: '',
        pitchPatternType: 'Scale',
        selectedInterval: 'P1',
        repeatRhythm: false,
        bottomOctave: 3,
        topOctave: 4
      }]
    }));
  };

  const handleRhythmPatternChange = (staffId, voiceIndex, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          return {
            ...voice,
            rhythmPattern: value
          };
        }
        return voice;
      })
    }));
  };

  const handleDeleteVoice = (staffId, voiceIndex) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].filter((_, idx) => idx !== voiceIndex)
    }));
  };

  const handleRepeatRhythmChange = (staffId, voiceIndex, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) =>
        idx === voiceIndex ? { ...voice, repeatRhythm: value } : voice
      )
    }));
  };

  const handleOctaveChange = (staffId, voiceIndex, type, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          if (type === 'bottom') {
            return {
              ...voice,
              bottomOctave: parseInt(value),
              topOctave: Math.max(parseInt(value), voice.topOctave)
            };
          } else {
            return {
              ...voice,
              topOctave: parseInt(value),
              bottomOctave: Math.min(parseInt(value), voice.bottomOctave)
            };
          }
        }
        return voice;
      })
    }));
  };

  const handlePitchPatternChange = (staffId, voiceIndex, type, interval = null) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          return {
            ...voice,
            pitchPatternType: type,
            selectedInterval: interval || voice.selectedInterval,
            customPitchPattern: voice.customPitchPattern || ''
          };
        }
        return voice;
      })
    }));
  };

  const handleCustomPitchPatternChange = (staffId, voiceIndex, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          return {
            ...voice,
            customPitchPattern: value
          };
        }
        return voice;
      })
    }));
  };

  const handleStartMeasureChange = (value) => {
    const newStart = parseInt(value);
    setStartMeasure(newStart);
    setEndMeasure(prev => Math.max(newStart, prev));
  };

  const handleEndMeasureChange = (value) => {
    setEndMeasure(Math.max(parseInt(value), startMeasure));
  };

  const handleAddModification = () => {
    setModifications(prev => [...prev, {
      type: 'rhythm_switch',
      originalRhythm: '',
      newRhythm: '',
      targetKey: 'C_major',
      transposeInterval: 'P1',
      transposeDirection: 'up'
    }]);
  };

  const handleRhythmSwitchChange = (index, field, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, [field]: value } : mod
    ));
  };

  const handleModificationTypeChange = (index, type) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? {
        ...mod,
        type,
        targetKey: type === 'key_change' ? 'C_major' : mod.targetKey,
        transposeInterval: type === 'transpose' ? 'P1' : mod.transposeInterval
      } : mod
    ));
  };

  const handleTransposeIntervalChange = (index, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, transposeInterval: value } : mod
    ));
  };

  const handleTransposeDirectionChange = (index, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, transposeDirection: value } : mod
    ));
  };

  const handleKeyChangeSelect = (index, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, targetKey: value } : mod
    ));
  };

  const handleDeleteModification = (index) => {
    setModifications(prev => prev.filter((_, idx) => idx !== index));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Create New Exercise"
      button_text={isCreating ? "Creating..." : "Create"}
      button_view={
        <button className="text-nowrap whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200">
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <span className="whitespace-nowrap">Create Drill</span>
        </button>
      }
      submit={handleSubmit}
    >
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Exercise Name
          </label>
          <input
            type="text"
            value={drillName}
            onChange={(e) => setDrillName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Enter exercise name"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Exercise Type
          </label>
          <select
            value={drillType}
            onChange={(e) => setDrillType(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="from_scratch">From Scratch</option>
            <option value="from_piece">From Piece</option>
          </select>
        </div>

        {drillType === 'from_piece' && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Select Piece
              </label>
              <QueryPiece
                selectedPiece={selectedPiece}
                ptype="musicxml"
                setSelectedPiece={setSelectedPiece}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Key Signature
              </label>
              <select
                value={keySignature}
                onChange={(e) => setKeySignature(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {keySignatures.map((key) => (
                  <option key={key} value={key}>
                    {key.replace('_', ' ')}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Excerpt Type
              </label>
              <select
                value={excerptType}
                onChange={(e) => setExcerptType(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="full">Full Piece</option>
                <option value="excerpt">Excerpt</option>
              </select>
            </div>

            {excerptType === 'excerpt' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Measure Range
                </label>
                <div className="flex items-center space-x-4">
                  <div className="flex-1">
                    <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                      Start Measure
                    </label>
                    <input
                      type="number"
                      min="1"
                      value={startMeasure}
                      onChange={(e) => handleStartMeasureChange(e.target.value)}
                      className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                      End Measure
                    </label>
                    <input
                      type="number"
                      min={startMeasure}
                      value={endMeasure}
                      onChange={(e) => handleEndMeasureChange(e.target.value)}
                      className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="border border-gray-200 dark:border-gray-700 rounded-lg">
              <div
                className="flex items-center justify-between p-4 cursor-pointer"
                onClick={() => setIsModificationsExpanded(!isModificationsExpanded)}
              >
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                  Drill Modifications
                </h3>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddModification();
                    }}
                    className="mr-4 px-3 py-1 text-xs font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                  >
                    Add Modification
                  </button>
                  <svg
                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isModificationsExpanded ? 'transform rotate-180' : ''
                      }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              {isModificationsExpanded && (
                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  {modifications.length === 0 ? (
                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                      No modifications added yet
                    </p>
                  ) : (
                    <div className="space-y-4">
                      {modifications.map((mod, index) => (
                        <ModificationRow
                          key={index}
                          modification={mod}
                          index={index}
                          onDelete={handleDeleteModification}
                          onTypeChange={handleModificationTypeChange}
                          onRhythmSwitchChange={handleRhythmSwitchChange}
                          onKeyChangeSelect={handleKeyChangeSelect}
                          onTransposeIntervalChange={handleTransposeIntervalChange}
                          onTransposeDirectionChange={handleTransposeDirectionChange}
                          keySignatures={keySignatures}
                          interval_mapper={interval_mapper}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {drillType === 'from_scratch' && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Key Signature
              </label>
              <select
                value={keySignature}
                onChange={(e) => setKeySignature(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {keySignatures.map((key) => (
                  <option key={key} value={key}>
                    {key.replace('_', ' ')}
                  </option>
                ))}
              </select>
            </div>

            {/* Staff 1 */}
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg">
              <div
                className="flex items-center justify-between p-4 cursor-pointer"
                onClick={() => toggleStaff('staff1')}
              >
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                  Staff 1
                </h3>
                <div className="flex items-center">
                  {voices.staff1.length < MAX_VOICES_PER_STAFF && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddVoice('staff1');
                      }}
                      className="mr-4 px-3 py-1 text-xs font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                    >
                      Add Voice
                    </button>
                  )}
                  <svg
                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${expandedStaffs.staff1 ? 'transform rotate-180' : ''
                      }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              {expandedStaffs.staff1 && (
                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  {voices.staff1.map((voice, index) => (
                    <VoiceRow
                      key={index}
                      staffId="staff1"
                      voiceIndex={index}
                      voice={voice}
                      onDelete={handleDeleteVoice}
                      onRhythmChange={handleRhythmPatternChange}
                      onPitchPatternChange={handlePitchPatternChange}
                      onCustomPitchChange={handleCustomPitchPatternChange}
                      onRepeatRhythmChange={handleRepeatRhythmChange}
                      onOctaveChange={handleOctaveChange}
                      interval_mapper={interval_mapper}
                    />
                  ))}
                  {voices.staff1.length === 0 && (
                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                      No voices added yet
                    </p>
                  )}
                </div>
              )}
            </div>

            {/* Staff 2 */}
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg">
              <div
                className="flex items-center justify-between p-4 cursor-pointer"
                onClick={() => toggleStaff('staff2')}
              >
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                  Staff 2
                </h3>
                <div className="flex items-center">
                  {voices.staff2.length < MAX_VOICES_PER_STAFF && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddVoice('staff2');
                      }}
                      className="mr-4 px-3 py-1 text-xs font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                    >
                      Add Voice
                    </button>
                  )}
                  <svg
                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${expandedStaffs.staff2 ? 'transform rotate-180' : ''
                      }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              {expandedStaffs.staff2 && (
                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  {voices.staff2.map((voice, index) => (
                    <VoiceRow
                      key={index}
                      staffId="staff2"
                      voiceIndex={index}
                      voice={voice}
                      onDelete={handleDeleteVoice}
                      onRhythmChange={handleRhythmPatternChange}
                      onPitchPatternChange={handlePitchPatternChange}
                      onCustomPitchChange={handleCustomPitchPatternChange}
                      onRepeatRhythmChange={handleRepeatRhythmChange}
                      onOctaveChange={handleOctaveChange}
                      interval_mapper={interval_mapper}
                    />
                  ))}
                  {voices.staff2.length === 0 && (
                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                      No voices added yet
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="pt-2">
              <div className="flex items-center">
                <input
                  id="let-all-voices-finish"
                  type="checkbox"
                  checked={letAllVoicesFinish}
                  onChange={(e) => setLetAllVoicesFinish(e.target.checked)}
                  className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="let-all-voices-finish"
                  className="ml-2 text-sm text-gray-700 dark:text-gray-300"
                >
                  Let all voices finish
                </label>
              </div>
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400 ml-6">
                *By default, we will cut off all voices when the staff 1, voice 1 finishes
              </p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CreateDrill; 