const PeriodEmblem = ({ period }) => {
  const getEmblemStyle = () => {
    switch (period?.toLowerCase()) {
      case 'renaissance':
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: 'Before 1600',
          displayName: 'Renaissance',
          initials: 'R'
        };
      case 'baroque':
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: '1600-1750',
          displayName: 'Baroque',
          initials: 'B'
        };
      case 'classical':
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: '1730-1820',
          displayName: 'Classical',
          initials: 'C'
        };
      case 'late_romantic':
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: '1850-1910',
          displayName: 'Late Romantic',
          initials: 'LR'
        };
      case 'early_romantic':
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: '1810-1850',
          displayName: 'Early Romantic',
          initials: 'ER'
        };
      case 'modern':
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: '1910-Present',
          displayName: 'Modern',
          initials: 'M'
        };
      default:
        return {
          bg: 'bg-violet-50 dark:bg-violet-900/20',
          text: 'text-violet-600 dark:text-violet-400',
          border: 'border-violet-200 dark:border-violet-800',
          years: 'Unknown',
          displayName: 'Unknown Period',
          initials: '?'
        };
    }
  };

  const style = getEmblemStyle();

  return (
    <div className="flex flex-col items-center">
      <div className={`flex items-center justify-center w-12 h-12 rounded-lg border ${style.border} ${style.bg} mb-2`}>
        <span className={`text-lg font-bold ${style.text}`}>
          {style.initials}
        </span>
      </div>
      <span className={`text-xs font-medium ${style.text}`}>{style.years}</span>
      <span className={`text-sm font-semibold ${style.text}`}>
        {style.displayName}
      </span>
    </div>
  );
};

export default PeriodEmblem; 