import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccountContext } from '../../stores/Account';

export default function StudentDashboardBanner() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, setUser, userInfo } = useContext(AccountContext);

  const handleReturn = () => {

    setUser({ user: user.user, student: null });
    setSearchParams(searchParams => {
      if (searchParams.has('ts_stud_id')) {
        searchParams.delete('ts_stud_id');
      }
      return searchParams;
    });
  };

  return (
    <div className="shadow-md w-full rounded-lg bg-amber-500 dark:bg-amber-600 p-1 mb-4 transition-colors duration-200 isolate flex items-center justify-center gap-x-6 overflow-hidden">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm/6 text-gray-900">
          <strong className="font-semibold">You are viewing student {userInfo.first_name || userInfo.last_name ? `${userInfo.first_name} ${userInfo.last_name}`.trim() : 'Subito'}          's dashboard.</strong>
        </p>
        <button onClick={handleReturn} className="mt-2 mb-2 text-violet-600 font-semibold text-sm hover:underline">Return to your dashboard.</button>
      </div>
    </div>
  )
}
