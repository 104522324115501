import SearchableDropdown from './SearchableDropdown';
import Modal from '../../components/Modal';
import useStateParam from '../../hooks/useStateParam';
import { Label } from '../../components/Input';
import { useSearchParams } from 'react-router-dom';
import QueryPiece from './QueryPiece';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Select from 'react-select';
import getTags from '../../api/getTags';
import { useContext } from 'react';
import { AccountContext } from '../../stores/Account';

const tagColors = {
  default: 'bg-violet-50 text-violet-700 dark:bg-violet-900/30 dark:text-violet-300'
};

const customSelectStyles = {
  control: (base) => ({
    ...base,
    borderRadius: '0.5rem',
    borderColor: 'rgb(209 213 219)',
    '&:hover': {
      borderColor: 'rgb(139 92 246)'
    }
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'rgb(245 243 255)',
    borderRadius: '9999px',
    padding: '2px 4px',
    '& > div': {
      color: 'rgb(124 58 237)'
    }
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'rgb(124 58 237)',
    ':hover': {
      backgroundColor: 'rgb(237 233 254)',
      color: 'rgb(109 40 217)'
    },
    borderRadius: '9999px'
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'rgb(139 92 246)' : base.backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected ? 'rgb(139 92 246)' : 'rgb(237 233 254)'
    }
  })
};

function AdvancedSearch(props) {
  const { user } = useContext(AccountContext);

  const [searchQuery, setSearchQuery] = useStateParam('search', undefined);
  const [composerSearch, setComposerSearch] = useStateParam('composer', undefined)

  const [instrument, setInstrument] = useStateParam('instrument', ["piano"]) //"cello", "violin"

  const [timePeriod, setTimePeriod] = useStateParam('period', ["baroque", "classical", "early_romantic", "late_romantic", "modern"])

  const [matchPeriod, setMatchPeriod] = useStateParam('match_period', "int", 0)
  const [matchKey, setMatchKey] = useStateParam('match_key', "int", 0)
  const [matchComposer, setMatchComposer] = useStateParam('match_composer', "int", 0)
  const [matchRhythm, setMatchRhythm] = useStateParam('match_rhythm', "int", 0)
  const [matchPitch, setMatchPitch] = useStateParam('match_pitch', 0)

  const [keySignature, setKeySignature] = useStateParam('key', ['C_major', 'A_minor',
    'G_major', 'E_minor',
    'D_major', 'B_minor',
    'A_major', 'F#_minor',
    'E_major', 'C#_minor',
    'B_major', 'G#_minor',
    'F#_major', 'D#_minor',
    'C#_major', 'A#_minor',
    'F_major', 'D_minor',
    'Bb_major', 'G_minor',
    'Eb_major', 'C_minor',
    'Ab_major', 'F_minor',
    'Db_major', 'Bb_minor',
    'Gb_major', 'Eb_minor',
    'Cb_major', 'Ab_minor'
  ])

  const [ptype, setPtype] = useStateParam('ptype', ["pdf", "musicxml", "all"])
  const [selectedTagsParam, setSelectedTagsParam] = useStateParam('tags', undefined);

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize search input with URL param value
  const [searchInput, setSearchInput] = useState(searchParams.get('search') || '');

  // Update input when URL param changes
  useEffect(() => {
    setSearchInput(searchParams.get('search') || '');
  }, [searchParams]);

  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(() => {
    // Initialize from URL parameter
    if (selectedTagsParam) {
      return selectedTagsParam.split(',').map(tag => ({
        value: tag,
        label: tag
      }));
    }
    return [];
  });

  // Load available tags
  useEffect(() => {
    getTags(user).then((res) => {
      setAvailableTags(res.tags.map(tag => ({
        value: tag,
        label: tag
      })));
    }).catch((err) => {
      console.error('Failed to load tags:', err);
    });
  }, [user]);

  // Update URL when tags change
  useEffect(() => {
    if (selectedTags.length > 0) {
      setSelectedTagsParam(selectedTags.map(tag => tag.value).join(','));
    } else {
      setSelectedTagsParam(undefined); // Remove from URL if no tags selected
    }
  }, [selectedTags]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    setSearchQuery(value || undefined);
  };

  const clearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Get preserved params
    const ts_stud_id = searchParams.get('ts_stud_id');
    const page = searchParams.get('page');
    const pieces = searchParams.get('pieces');

    // Create new URLSearchParams with only preserved params
    const newParams = new URLSearchParams();
    if (ts_stud_id) newParams.set('ts_stud_id', ts_stud_id);
    if (page) newParams.set('page', page);
    if (pieces) newParams.set('pieces', pieces);

    // Update URL and reload
    setSearchParams(newParams);
    window.location.reload();
  };

  const handlePtypeChange = (e) => {
    setPtype(e.target.value);
  };

  const [semanticPiece, setSemanticPiece] = useState(() => {
    const id = searchParams.get('match_piece_id');
    const name = searchParams.get('match_piece_name');
    return id && name ? { id, name } : null;
  });

  // Update URL when semantic piece changes
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (semanticPiece) {
      newParams.set('match_piece_id', semanticPiece.id);
      newParams.set('match_piece_name', semanticPiece.title);
    } else {
      newParams.delete('match_piece_id');
      newParams.delete('match_piece_name');
    }
    setSearchParams(newParams, { replace: true });
  }, [semanticPiece]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(searchParams);

    // Add selected tags to search params
    if (selectedTags.length > 0) {
      params.set('tags', selectedTags.map(tag => tag.value).join(','));
    } else {
      params.delete('tags');
    }

    // Add semantic search parameters if a piece is selected
    if (semanticPiece) {
      params.set('match_piece_id', semanticPiece.id);
      params.set('match_piece_name', semanticPiece.title);
    } else {
      params.delete('match_piece_id');
      params.delete('match_piece_name');
    }

    window.location.reload();
  };

  return (
    <div className="flex space-x-2">
      <Modal
        title={<span className="whitespace-nowrap">{props.other_title ? props.other_title : "Advanced search"}</span>}
        button_text={<span className="whitespace-nowrap">Apply filters</span>}
        submit={handleSubmit}
        button_view={
          <button
            className="text-nowrap whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
            <span className="whitespace-nowrap">{props.other_title ? props.other_title : "Advanced search"}</span>
          </button>
        }
      >
        <div className="mb-6">
          <Label label="Search" />
          <input
            type="text"
            value={searchInput}
            onChange={handleSearchChange}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Search by title, composer, or genre..."
          />
        </div>
        <div
          className="flex flex-col w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
          <div className="w-full">
            <Label label="Instrument" />
            <select id="instruments"
              onChange={(e) => setInstrument(e.target.value)}
              value={instrument}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              <option selected value="-">Select an instrument</option>
              <option value="piano">Piano</option>
              {/*<option value="violin">Violin</option>
              <option value="cello">Cello</option>*/}
            </select>
          </div>
          <div className="w-full">
            <Label label="Time Period" />
            <select
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
              disabled={semanticPiece && matchPeriod === 1}
              className={`bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${semanticPiece && matchPeriod === 1
                ? 'opacity-50 cursor-not-allowed bg-gray-100 dark:bg-gray-800'
                : ''
                }`}
            >
              <option value="baroque">Baroque</option>
              <option value="classical">Classical</option>
              <option value="early_romantic">Early Romantic</option>
              <option value="late_romantic">Late Romantic</option>
              <option value="modern">Modern</option>
            </select>
            {semanticPiece && matchPeriod === 1 && (
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Using time period from selected piece
              </p>
            )}
          </div>
          {/*<div className="w-full">
            <Label label="Difficulty Score" />
            <select id="time_periods"
              onChange={(e) => setDifficulty(e.target.value)}
              value={difficulty}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              <option selected value="-">Choose a target difficulty</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>*/}
        </div>
        <div
          className="flex flex-col w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
          <div className="w-full">
            <Label label="Composer" />
            <input type="text" id="first_name"
              onChange={(e) => setComposerSearch(e.target.value)}
              value={composerSearch}
              disabled={semanticPiece && matchComposer === 1}
              className={`bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${semanticPiece && matchComposer === 1
                ? 'opacity-50 cursor-not-allowed bg-gray-100 dark:bg-gray-800'
                : ''
                }`}
              placeholder="Wolfgang Amadeus Mozart" required />
            {semanticPiece && matchComposer === 1 && (
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Using composer from selected piece
              </p>
            )}
          </div>
          <div className="w-full">
            <Label label="Key Signature" />
            <select
              value={keySignature}
              onChange={(e) => setKeySignature(e.target.value)}
              disabled={semanticPiece && matchKey === 1}
              className={`bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${semanticPiece && matchKey === 1
                ? 'opacity-50 cursor-not-allowed bg-gray-100 dark:bg-gray-800'
                : ''
                }`}
            >
              <option value="C_major">C Major</option>
              <option value="A_minor">A Minor</option>
              <option value="G_major">G Major</option>
              <option value="E_minor">E Minor</option>
              <option value="D_major">D Major</option>
              <option value="B_minor">B Minor</option>
              <option value="A_major">A Major</option>
              <option value="F#_minor">F# Minor</option>
              <option value="E_major">E Major</option>
              <option value="C#_minor">C# Minor</option>
              <option value="B_major">B Major</option>
              <option value="G#_minor">G# Minor</option>
              <option value="F#_major">F# Major</option>
              <option value="D#_minor">D# Minor</option>
              <option value="C#_major">C# Major</option>
              <option value="A#_minor">A# Minor</option>
              <option value="F_major">F Major</option>
              <option value="D_minor">D Minor</option>
              <option value="Bb_major">Bb Major</option>
              <option value="G_minor">G Minor</option>
              <option value="Eb_major">Eb Major</option>
              <option value="C_minor">C Minor</option>
              <option value="Ab_major">Ab Major</option>
              <option value="F_minor">F Minor</option>
              <option value="Db_major">Db Major</option>
              <option value="Bb_minor">Bb Minor</option>
              <option value="Gb_major">Gb Major</option>
              <option value="Eb_minor">Eb Minor</option>
              <option value="Cb_major">Cb Major</option>
              <option value="Ab_minor">Ab Minor</option>
            </select>
            {semanticPiece && matchKey === 1 && (
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Using key signature from selected piece
              </p>
            )}
          </div>
        </div>
        <div className="w-full mb-2 sm:mb-6">
          <Label label="Piece Type" />
          <div className="flex flex-row w-full">
            <div className="flex items-center w-full">
              <input id="bordered-radio-2" type="radio"
                value="all" checked={ptype === 'all'}
                onChange={handlePtypeChange} name="bordered-radio" className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label for="bordered-radio-2" className="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                <span className="whitespace-nowrap">All Pieces</span>
              </label>
            </div>
            <div className="flex items-center w-full">
              <input checked={ptype === 'musicxml'} onChange={handlePtypeChange} id="bordered-radio-2" type="radio" value="musicxml" name="bordered-radio" className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label for="bordered-radio-2" className="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                <span className="whitespace-nowrap">MusicXML Only</span>
              </label>
            </div>
            <div className="flex items-center w-full">
              <input checked={ptype === 'pdf'} onChange={handlePtypeChange} id="bordered-radio-2" type="radio" value="pdf" name="bordered-radio" className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label for="bordered-radio-2" className="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                <span className="whitespace-nowrap">PDF Only</span>
              </label>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="mb-2">
            <Label label="Search by Tags" />
            <Select
              isMulti
              options={availableTags}
              value={selectedTags}
              onChange={setSelectedTags}
              placeholder="Select tags..."
              styles={customSelectStyles}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Find pieces similar to
            </label>
            <QueryPiece
              selectedPiece={semanticPiece}
              setSelectedPiece={setSemanticPiece}
              ptype="musicxml"
              placeholder="Select a piece to match..."
            />
          </div>

          {semanticPiece && (
            <div>
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
                What aspects would you like to match?
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {[
                  {
                    key: 'similar_rhythm',
                    state: matchRhythm,
                    setState: setMatchRhythm,
                    label: 'Similar Rhythm',
                    icon: (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                      </svg>
                    )
                  },
                  {
                    key: 'similar_pitch',
                    state: matchPitch,
                    setState: setMatchPitch,
                    label: 'Similar Pitch Pattern',
                    icon: (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 15V9h2.67l-4-6-4 6H10v6H7v5h4v-2h2v2h4v-5z" />
                      </svg>
                    )
                  },
                  {
                    key: 'match_key',
                    state: matchKey,
                    setState: setMatchKey,
                    label: 'Key Signature',
                    icon: (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    )
                  },
                  {
                    key: 'match_period',
                    state: matchPeriod,
                    setState: setMatchPeriod,
                    label: 'Time Period',
                    icon: (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    )
                  },
                  {
                    key: 'match_composer',
                    state: matchComposer,
                    setState: setMatchComposer,
                    label: 'Composer',
                    icon: (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                    )
                  }
                ].map(({ key, state, setState, label, icon }) => (
                  <button
                    key={key}
                    onClick={() => setState(state === 1 ? 0 : 1)}
                    type="button"
                    className={`flex items-center p-3 rounded-lg border-2 transition-all duration-200 ${state === 1
                      ? 'border-violet-500 bg-violet-50 text-violet-700 dark:border-violet-400 dark:bg-violet-900/30 dark:text-violet-300'
                      : 'border-gray-200 hover:border-violet-200 dark:border-gray-700 dark:hover:border-violet-800'
                      }`}
                  >
                    <div className={`${state === 1
                      ? 'text-violet-600 dark:text-violet-400'
                      : 'text-gray-400 dark:text-gray-500'
                      }`}>
                      {icon}
                    </div>
                    <span className={`ml-3 text-sm font-medium ${state === 1
                      ? 'text-violet-700 dark:text-violet-300'
                      : 'text-gray-700 dark:text-gray-300'
                      }`}>
                      {label}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <button
        onClick={clearFilters}
        type="button"
        title="Clear all filters"
        className="p-2 text-gray-500 hover:text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 dark:text-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-gray-300 transition-all duration-300"
      >
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  )
}

export default AdvancedSearch