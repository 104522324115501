import API from ".";

const affiliateWithTeacher = (user, teacher_code) => {

  let obj = {
    "teacher_code": teacher_code
  }

  return new API(user).postRequest('/teacher/affiliate', obj);
}

export default affiliateWithTeacher;