interface TeacherInfo {
  access_code_used: string;
  business_city: string;
  business_country: string;
  business_name: string;
  business_state: string;
  business_street_addr: string;
  business_zipcode: string;
  hourly_rate: number;
  id: string;
  lesson_types: string;
  studio_size: number;
  subito_use: string;
  teacher_code: string;
  teacher_id: string;
}

interface UserInfoInput {
  first_name?: string;
  last_name?: string;
  email?: string;
  instrument?: string;
  joined_date?: string;
  profile_pic?: string;
  role?: string;
  pending_confirmation?: boolean;
  teacher_name?: string;
  student_level?: string;
  street_addr?: string;
  city_addr?: string;
  state_addr?: string;
  country_addr?: string;
  zipcode_addr?: string;
  ts_stud_id?: string;
  ts_flag?: number;
  teacher_info?: TeacherInfo;
}

class UserInfo {
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly email: string;
  public readonly pending_confirmation: boolean;
  public readonly teacher_name: string;
  public readonly instrument: string;
  public readonly date_joined: string;
  public readonly profile_pic: string;
  public readonly role: string;
  public readonly student_level: string;
  public readonly street_addr: string;
  public readonly city_addr: string;
  public readonly state_addr: string;
  public readonly country_addr: string;
  public readonly zipcode_addr: string;
  public readonly ts_stud_id: string;
  public readonly ts_flag: number;
  public readonly teacher_info: TeacherInfo | null;

  constructor(input: UserInfoInput) {
    this.first_name = input.first_name ?? '';
    this.last_name = input.last_name ?? '';
    this.email = input.email ?? '';
    this.instrument = input.instrument ?? '';
    this.pending_confirmation = input.pending_confirmation ?? false;
    this.teacher_name = input.teacher_name ?? '';
    this.date_joined = input.joined_date ?? '';
    this.profile_pic = input.profile_pic ?? '';
    this.role = input.role ?? '';
    this.student_level = input.student_level ?? '';
    this.street_addr = input.street_addr ?? '';
    this.city_addr = input.city_addr ?? '';
    this.state_addr = input.state_addr ?? '';
    this.country_addr = input.country_addr ?? '';
    this.zipcode_addr = input.zipcode_addr ?? '';
    this.ts_stud_id = input.ts_stud_id ?? '';
    this.ts_flag = input.ts_flag ?? 0;
    this.teacher_info = input.teacher_info ?? null;
  }
}

export default UserInfo;
export type { TeacherInfo };
