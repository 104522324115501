import React, { useState, useMemo, useEffect, useContext } from 'react';
import WeeklyPractice from './WeeklyPractice';
import PiecesPlayedChart from './PiecesPlayedChart';
import Pagination from '../components/Pagination';
import practiceTable from '../../api/practiceTable';
import { AccountContext } from '../../stores/Account';

const PracticeLog = ({ logs = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const currentLogs = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return logs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, logs]);

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">Student Name</th>
            <th scope="col" className="px-6 py-3">Piece/Drill</th>
            <th scope="col" className="px-6 py-3">Time</th>
            <th scope="col" className="px-6 py-3">Date</th>
          </tr>
        </thead>
        <tbody>
          {currentLogs.map((log, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                {log.user_name}
              </td>
              <td className="px-6 py-4">{log.piece_title}</td>
              <td className="px-6 py-4">{log.practice_minutes}</td>
              <td className="px-6 py-4">{log.practice_date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <Pagination
          activePage={currentPage}
          totalResults={logs.length}
          resultsPerPage={itemsPerPage}
          onChange={page => setCurrentPage(page)}
          label="Practice Log Navigation"
        />
      </div>
    </div>
  );
};

const PracticeWidget = (props) => {
  const [timeRange, setTimeRange] = useState(7);
  const [showLog, setShowLog] = useState(false);

  const handleTimeRangeChange = (value) => {
    setTimeRange(value);
  };

  const [practiceLogData, setPracticeLogData] = useState([]);
  const { user } = useContext(AccountContext);

  useEffect(() => {
    practiceTable(user, props.student_id).then((dat) => {
      setPracticeLogData(dat);
    });
  }, []);


  return (
    <div className="flex flex-col space-y-4 mb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-gray-900 dark:text-white">
          {showLog ? 'Practice Log' : 'Practice Overview'}
        </h3>
        <div>
          <button
            onClick={() => setShowLog(!showLog)}
            className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200
                ${showLog
                ? 'text-violet-600 bg-violet-50 hover:bg-violet-100 dark:text-violet-400 dark:bg-violet-900/20 dark:hover:bg-violet-900/30'
                : 'text-gray-700 bg-gray-100 hover:bg-gray-200 dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600'
              }`}
          >
            {showLog ? (
              <>
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19l-7-7 7-7m5 14l7-7-7-7" />
                </svg>
                View Overview
              </>
            ) : (
              <>
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
                View Log
              </>
            )}
          </button>
        </div>
      </div>

      {showLog ? (
        <PracticeLog logs={practiceLogData} />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <WeeklyPractice timeRange={timeRange} student_id={props.student_id} />
          <PiecesPlayedChart
            timeRange={timeRange}
            student_id={props.student_id}
            onTimeRangeChange={handleTimeRangeChange}
          />
        </div>
      )}
    </div>
  );
};

export default PracticeWidget; 