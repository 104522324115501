import { useState, useContext, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { composer_to_pic, format_period } from "../../utils/music";
import { daysSinceDate } from "../../utils/datetime";
import { AccountContext } from "../../stores/Account";
import PDFViewer from "../components/PDFViewer";
import FlagPiece from "../components/FlagPiece";
import { pdfjs } from 'react-pdf';
import MultiPageViewPDF from "../components/MultiPageViewPDF";
import getPiece from "../../api/getPiece";
import starPiece from "../../api/starPiece";
import { useParams } from "react-router-dom";
import PieceType from "../../types/piece";
import Heading from "../components/Heading";
import MultiPageViewFlat from "../components/MultiPageViewFlat";
import { getInitial } from "../../utils/string";
import viewPiece from "../../api/viewPiece";
import AddToList from "../components/AddToList";
import CreateDrill from "../components/CreateDrill";
import FlatEmbed from "../components/FlatEmbed";
import PeriodEmblem from "../components/PeriodEmblem";
import PieceCard from "../components/PieceCard";
import PracticeWidget from '../components/PracticeWidget';
import Pagination from '../components/Pagination';
import { motion } from "framer-motion";
import logPractice from "../../api/logPractice";
import piecePracticeLog from "../../api/piecePracticeLog";
import listDrills from "../../api/listDrills";
import DrillsCard from "../components/DrillsCard";
import createTag from '../../api/createTag';
import { isEmptyString } from "../../utils/string";
import updatePracticeNotes from "../../api/practiceNotes";
import updatePieceNotes from "../../api/pieceNotes";
const StarIcon = ({ filled, onClick }) => (
  <svg
    style={{ pointerEvents: 'auto' }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={filled ? "text-yellow-300" : "text-gray-400"}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

function Piece(props) {
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [fullScreenPDF, setFullScreenPDF] = useState(false);
  const [fullScreenFlat, setFullScreenFlat] = useState(false);
  const [piece, setPiece] = useState(props.piece ?? new PieceType({}));
  const [starred, setStarred] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('view');
  const [currentPage, setCurrentPage] = useState(1);
  const [timeRange, setTimeRange] = useState(7);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const recordsPerPage = 5;
  const [startTime] = useState(Date.now());

  const [practiceLog, setPracticeLog] = useState(null);
  const [exercises, setExercises] = useState(null);

  const [showTagInput, setShowTagInput] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isAddingTag, setIsAddingTag] = useState(false);

  const [showTeacherNoteInput, setShowTeacherNoteInput] = useState(false);
  const [newTeacherNote, setNewTeacherNote] = useState('');
  const [isAddingTeacherNote, setIsAddingTeacherNote] = useState(false);

  const [isEditingPracticeNotes, setIsEditingPracticeNotes] = useState(false);
  const [practiceNotesInput, setPracticeNotesInput] = useState('');
  const [isUpdatingPracticeNotes, setIsUpdatingPracticeNotes] = useState(false);

  const timeRanges = [
    { value: 7, label: 'Last 7 days' },
    { value: 30, label: 'Last 30 days' },
    { value: 90, label: 'Last 3 months' },
    { value: 365, label: 'Last year' },
    { value: 1000000, label: 'All time' }
  ];

  const onTimeRangeChange = (value) => {
    setTimeRange(value);
    setCurrentPage(1);
    // Add any additional logic for when time range changes
  };

  // Add click outside handler for dropdown
  const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));

  const handleTimeRangeChange = (e) => {
    e.preventDefault();
    setTimeRange(e.target.value);
    setCurrentPage(1);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const { user, userInfo } = useContext(AccountContext);

  useEffect(() => {
    if (piece.id) {
      piecePracticeLog(user, piece.id, timeRange).then((res) => {
        console.log('piece practice log', res);
        setPracticeLog(res);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [piece.id, timeRange])

  useEffect(() => {
    if (piece.id) {
      listDrills(user, piece.id).then((res) => {
        setExercises(res.drills);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [piece.id])

  useEffect(() => {
    if (props.piece) {
      setPiece(props.piece);
    }
  }, [props.piece])

  useEffect(() => {
    if (!props.piece) {
      getPiece(user, props.id ?? id).then((res) => {
        setPiece(res);
        if (res.starred === 1) {
          setStarred(true);
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [id, props.id, props.piece])

  useEffect(() => {
    viewPiece(user, props.id ?? id);
  }, [])

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }

  const handleClickOutsideDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutsideDropdown, isDisabled);

  const star = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    starPiece(user, piece.id).then(() => {
      setStarred((starred) => !starred)
    }).catch((err) => {
      console.log(err);
    })
  }

  console.log(piece);

  // Mock practice log data - replace with API data
  const practiceLogData = [...Array(35)].map((_, i) => ({
    id: i + 1,
    minutes: Math.floor(Math.random() * 30) + 15,
    // Format date as MM/DD/YY HH:MM:SS
    date: new Date(Date.now() - (i * 24 * 60 * 60 * 1000)).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(',', '')
  }));

  // Calculate pagination
  const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
  const indexOfLastRecord = indexOfFirstRecord + recordsPerPage;
  const currentRecords = practiceLogData.slice(indexOfFirstRecord, indexOfLastRecord);

  // Function to log practice time
  const logPracticeTime = () => {
    if (piece.id) {
      console.log("Logging practice time", startTime, Date.now(), piece.id);
      logPractice(user, startTime, Date.now(), piece.id)
        .catch(err => console.error('Failed to log practice:', err));
    }
  };

  // Handle page refresh/close
  useEffect(() => {
    const handleBeforeUnload = () => {
      logPracticeTime();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [piece?.id]);

  // Handle navigation away (component unmount)
  useEffect(() => {
    return () => {
      console.log("Unmounting piece");
      logPracticeTime();
    };
  }, [piece?.id]);

  console.log(piece);

  const tagColors = {
    default: 'bg-violet-50 text-violet-700 dark:bg-violet-900/30 dark:text-violet-300'
  };

  const getTagColor = (tag) => {
    return tagColors[tag.toLowerCase()] || tagColors.default;
  };

  const handleAddTag = async (e) => {
    e.preventDefault();
    if (!piece.id) return;

    if (!newTag.trim()) return;

    setIsAddingTag(true);
    try {
      const res = await createTag(user, piece.id, newTag.trim().replaceAll(" ", "_"));
      // Refresh piece data to get updated tags
      window.location.reload();
    } catch (error) {
      console.error('Failed to add tag:', error);
    } finally {
      setIsAddingTag(false);
    }
  };

  const handleAddTeacherNote = async (e) => {
    if (e) e.preventDefault();
    if (!piece.id) return;
    if (!newTeacherNote.trim()) return;

    setIsAddingTeacherNote(true);
    try {
      const res = await updatePieceNotes(user, piece.id, newTeacherNote.trim());
      window.location.reload();
    } catch (error) {
      console.error('Failed to add teacher note:', error);
    } finally {
      setIsAddingTeacherNote(false);
    }
  };

  const handleUpdatePracticeNotes = async () => {
    if (!piece.id) return;

    setIsUpdatingPracticeNotes(true);
    try {
      const res = await updatePracticeNotes(user, piece.id, practiceNotesInput);
      window.location.reload();
    } catch (error) {
      console.error('Failed to update practice notes:', error);
    } finally {
      setIsUpdatingPracticeNotes(false);
    }
  };

  // Initialize practice notes when piece loads
  useEffect(() => {
    if (piece?.practice_notes?.notes) {
      setPracticeNotesInput(piece.practice_notes.notes);
    }
  }, [piece]);

  return (
    <div className="w-full bg-white rounded-lg shadow-md flex flex-col dark:bg-gray-800 pb-4">
      <div className="flex flex-row justify-between items-center">
        {!piece.title ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-600 w-96 m-4"></div>
          </div>
        ) : (
          <Heading title={piece.title} />
        )}
        <div className="p-4">
          <StarIcon onClick={star} filled={starred} />
        </div>
      </div>

      <div className="flex flex-row items-center justify-between w-full px-4 mb-4">
        <div className='flex items-center'>
          <div className="flex-shrink-0">
            {composer_to_pic(piece.composer_lname) !== "none" ?
              <img
                src={composer_to_pic(piece.composer_lname)}
                className="h-10 w-10 bg-gray-300 rounded-lg object-cover"
              /> : <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-600">
                <span className="font-medium text-gray-600 dark:text-gray-300">{getInitial(piece.composer_fname) + getInitial(piece.composer_lname)}</span>
              </div>
            }
          </div>
          <div className="ml-4">
            <div className="text-sm font-semibold text-black truncate dark:text-white">{piece.composer_fname} {piece.composer_lname}</div>
            <div className="text-sm text-gray-500 truncate dark:text-gray-400">{format_period(piece.time_period)}</div>
          </div>
        </div>
      </div>
      {!props.sightreading &&
        <div className="px-4 py-3 flex justify-between items-center">
          <div className="flex flex-wrap gap-3">
            <button
              onClick={() => setActiveTab('view')}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'view'
                ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                }`}
            >
              View
            </button>
            <button
              onClick={() => setActiveTab('piece_info')}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'piece_info'
                ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                }`}
            >
              <span className="whitespace-nowrap">Piece Info</span>
            </button>
            <button
              onClick={() => setActiveTab('practice_history')}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'practice_history'
                ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                }`}
            >
              <span className="whitespace-nowrap">Practice</span>
            </button>
            <button
              onClick={() => setActiveTab('exercises')}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'exercises'
                ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                }`}
            >
              <span className="whitespace-nowrap">Exercises</span>
            </button>
            <button
              onClick={() => setActiveTab('variations')}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'variations'
                ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                }`}
            >
              <span className="whitespace-nowrap">Variations</span>
            </button>
          </div>

          <div className="flex flex-row space-x-3">
            <CreateDrill piece={piece} />
            <AddToList
              piece_id={piece.id}
              setDisabled={() => { }}
              button_view={
                <button className="text-nowrap whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200">
                  <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                  </svg>
                  <span className="whitespace-nowrap">Add to List</span>
                </button>
              }
            />
          </div>
        </div>}

      {activeTab === 'view' && (
        <div className="px-4 relative">
          {piece.type === 'pdf' ? (
            <>
              {fullScreenPDF && <MultiPageViewPDF file={piece.pdf_link} onClose={() => setFullScreenPDF(false)} />}
              <PDFViewer height="auto" src={piece.pdf_link}>
                <div className="top-4 right-4 absolute z-10">
                  <svg onClick={() => { setFullScreenPDF(true) }} xmlns="http://www.w3.org/2000/svg" className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300" viewBox="0 0 24 24">
                    <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
                  </svg>
                </div>
              </PDFViewer>
            </>
          ) : piece.type === 'musicxml' ? (
            <>
              {fullScreenFlat && (
                <MultiPageViewFlat
                  title={piece.title}
                  composer={`${piece.composer_fname} ${piece.composer_lname}`}
                  scoreId={piece.flat_score_id}
                  sharingKey={piece.flat_sharing_key}
                  onClose={() => setFullScreenFlat(false)}
                />
              )}
              <div className="relative">
                <div className="top-4 right-4 absolute z-10">
                  <svg
                    onClick={() => setFullScreenFlat(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300"
                    viewBox="0 0 24 24"
                  >
                    <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
                  </svg>
                </div>
                <FlatEmbed
                  scoreId={piece.flat_score_id}
                  sharingKey={piece.flat_sharing_key}
                  height={600}
                />
              </div>
            </>
          ) : null}
          <FlagPiece id={piece.id} />
        </div>
      )}

      {activeTab === 'piece_info' && (
        <div className="p-6 space-y-6">
          <div className="flex items-start space-x-6">
            <PeriodEmblem period={piece.time_period} />
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                Historical Context
              </h3>
              <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
                {piece.history || "We do not have a historical context for this piece yet."}
              </p>
            </div>
          </div>
          {piece && (
            <div className="mb-6">
              <div className="px-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Tags
                  </h2>
                  <button
                    onClick={() => setShowTagInput(true)}
                    className="p-1 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300"
                    title="Add tag"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-wrap gap-2">
                  {showTagInput && (
                    <div className="flex items-center">
                      <input
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
                        className="px-3 py-1 text-sm border border-gray-300 rounded-full focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Enter new tag..."
                        autoFocus
                      />
                      <button
                        onClick={handleAddTag}
                        className="ml-2 p-1 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setShowTagInput(false);
                          setNewTag('');
                        }}
                        className="ml-1 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  )}
                  {(!piece.tags || piece.tags.length === 0) && !showTagInput && (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      No tags yet
                    </p>
                  )}
                  {piece.tags && piece.tags.map((tag, index) => (
                    <span
                      key={index}
                      className={`inline-flex items-center px-3 py-1 text-sm font-medium rounded-full ${getTagColor(tag)}`}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
          {piece && userInfo?.role === 'teacher' && (
            <div className="mb-6">
              <div className="px-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Teacher Notes
                  </h2>
                  <button
                    onClick={() => setShowTeacherNoteInput(true)}
                    className="p-1 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300"
                    title="Add note"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col space-y-2">
                  {piece?.piece_notes?.map((note, index) => (
                    <div
                      key={index}
                      className="p-4 bg-violet-50 rounded-lg dark:bg-violet-900/20"
                    >
                      <div className="flex items-start space-x-3">
                        <div className="flex-shrink-0">
                          {note?.profile_pic ? (
                            <img
                              src={`${note.profile_pic}`}
                              alt={`${note.first_name} ${note.last_name}`}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          ) : (
                            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-violet-100 rounded-full dark:bg-violet-900">
                              <span className="font-medium text-violet-600 dark:text-violet-300">
                                {getInitial(note.first_name) + getInitial(note.last_name)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-violet-700 dark:text-violet-300">
                              {note.first_name} {note.last_name}
                            </p>
                            <span className="text-xs text-violet-500 dark:text-violet-400">
                              {new Date(note.time).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </span>
                          </div>
                          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300 whitespace-pre-wrap">
                            {note.notes}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {showTeacherNoteInput && (
                    <div className="flex items-center space-x-2">
                      <input
                        type="text"
                        value={newTeacherNote}
                        onChange={(e) => setNewTeacherNote(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleAddTeacherNote()}
                        className="flex-1 px-3 py-2 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Add a note..."
                        autoFocus
                      />
                      <button
                        onClick={handleAddTeacherNote}
                        disabled={isAddingTeacherNote || !newTeacherNote.trim()}
                        className="p-2 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300 disabled:opacity-50"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setShowTeacherNoteInput(false);
                          setNewTeacherNote('');
                        }}
                        className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Sample Recordings
            </h3>
            <div className="p-6">
              <div className="flex flex-col items-center justify-center py-12 text-center">
                <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                  Coming Soon
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md">
                  We're working on bringing you high quality recordings for this piece and more to accelerate your practice.
                </p>
              </div>
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="aspect-video rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800">
                <iframe
                  className="w-full h-full"
                  src="https://www.youtube.com/embed/4Tr0otuiQuU"
                  title="Example Recording 1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="aspect-video rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800">
                <iframe
                  className="w-full h-full"
                  src="https://www.youtube.com/embed/zucBfXpCA6s"
                  title="Example Recording 2"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>*/}
          </div>

          <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
            <div className="bg-violet-50 dark:bg-violet-900/20 rounded-lg p-4">
              <p className="text-sm text-violet-600 dark:text-violet-400">
                If you have more to add to this piece's historical context or can recommend some better recordings,
                please email us at <a href="mailto:acn57@cornell.edu" className="underline hover:text-violet-700 dark:hover:text-violet-300">
                  acn57@cornell.edu</a>. We would love to improve this page with your help!
              </p>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'practice_history' && (
        <div className="p-6 space-y-6">
          <div className="dark:border-gray-700">
            {/* Overview Statistics */}
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-6">
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Total Practice Time</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  {practiceLog && practiceLog.summary && practiceLog.summary.total_practice_minutes ? practiceLog.summary.total_practice_minutes.toFixed(0) : "0"} minutes
                </p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Avg. Time per Day</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  {practiceLog && practiceLog.summary && practiceLog.summary.avg_minutes_per_day ? practiceLog.summary.avg_minutes_per_day.toFixed(0) : "0"} minutes
                </p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Days Practiced</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  {practiceLog && practiceLog.summary && practiceLog.summary.days_practiced ? practiceLog.summary.days_practiced : "0"} days
                </p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">First Practice</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  {practiceLog && practiceLog.summary && practiceLog.summary.first_date_practiced ? new Date(practiceLog.summary.first_date_practiced).toLocaleDateString() : "N/A"}
                </p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Last Practice</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">
                  {practiceLog && practiceLog.summary && practiceLog.summary.most_recent_date_practiced ? new Date(practiceLog.summary.most_recent_date_practiced).toLocaleDateString() : "N/A"}
                </p>
              </div>
            </div>

            {/* Time Range Selector */}
            <div className="flex items-center justify-between mb-6 border-t border-gray-200 dark:border-gray-700 pt-6">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Practice Log
              </h3>
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
                  type="button"
                >
                  {timeRanges.find(r => r.value === timeRange)?.label}
                  <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                  </svg>
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-44 bg-white dark:bg-gray-700 rounded-lg shadow-lg z-10">
                    {timeRanges.map((range) => (
                      <button
                        key={range.value}
                        onClick={() => {
                          setIsDropdownOpen(false);
                          onTimeRangeChange(range.value);
                        }}
                        className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600
                      ${timeRange === range.value ? 'text-violet-600 dark:text-violet-400' : 'text-gray-700 dark:text-gray-200'}`}
                      >
                        {range.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Practice Log */}
            {(!practiceLog?.log || practiceLog.log.length === 0) ? (
              <div className="flex flex-col items-center justify-center py-12 text-center">
                <div className="w-16 h-16 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center mb-4">
                  <svg className="w-8 h-8 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                  No practice records yet
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 max-w-sm">
                  Start practicing this piece and we'll keep track of your progress here.
                </p>
              </div>
            ) : (
              <div className="space-y-3">
                {practiceLog && practiceLog.log && practiceLog.log
                  .slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
                  .map((record) => (
                    <div
                      key={record.practice_date}
                      className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          <div className="w-10 h-10 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center">
                            <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {record.practice_minutes} minutes
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              {new Date(record.practice_date.replace(/(\d{2})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})/, '20$3-$1-$2T$4:$5:$6')).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <span className="px-2.5 py-0.5 text-xs font-medium text-violet-600 bg-violet-100 rounded-full dark:bg-violet-900/30 dark:text-violet-400">
                          {new Date(record.practice_date).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {/* Pagination */}
            {practiceLog?.log?.length > recordsPerPage && (
              <div className="mt-4 w-full">
                <Pagination
                  page={currentPage}
                  totalResults={practiceLog && practiceLog.log ? practiceLog.log.length : 0}
                  resultsPerPage={recordsPerPage}
                  onChange={page => setCurrentPage(page)}
                  label="Practice Log Navigation"
                />
              </div>
            )}
          </div>

          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Practice Notes
              </h3>
              <div className="flex space-x-3">
                {isEditingPracticeNotes ? (
                  <>
                    <button
                      onClick={() => {
                        setPracticeNotesInput(piece.practice_notes || '');
                        setIsEditingPracticeNotes(false);
                      }}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                      disabled={isUpdatingPracticeNotes}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleUpdatePracticeNotes}
                      disabled={isUpdatingPracticeNotes}
                      className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200 disabled:opacity-50"
                    >
                      {isUpdatingPracticeNotes ? 'Saving...' : 'Save Changes'}
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setIsEditingPracticeNotes(true)}
                    className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                    Edit Notes
                  </button>
                )}
              </div>
            </div>
            {isEditingPracticeNotes ? (
              <textarea
                value={practiceNotesInput}
                onChange={(e) => setPracticeNotesInput(e.target.value)}
                rows="4"
                className="w-full p-3 text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholder="Add notes about this drill..."
              />
            ) : (
              <div className="text-gray-600 dark:text-gray-300">
                {piece.practice_notes?.notes || "This piece has no practice notes yet."}
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === 'exercises' && (
        <div className="p-6">
          <div className="flex flex-row justify-center sm:justify-start lg:ml-4 flex-wrap gap-1 gap-y-4 overflow-y-scroll no-scrollbar">
            {exercises && exercises.length > 0 && exercises.map((drill) => (
              <DrillsCard
                key={drill.id}
                drill={drill}
              />
            ))}
            {exercises && exercises.length === 0 && (
              <div className="w-full col-span-full flex flex-col items-center justify-center py-12">
                <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                  No exercises yet
                </h3>
                <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                  Create your first exercise for this piece to get started!
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === 'variations' && (
        <div className="p-6">
          <div className="flex flex-col items-center justify-center py-12 text-center">
            <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
              Coming Soon
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md">
              We're working on bringing you the ability to create custom variations of this piece for your own practice. Stay tuned!
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Piece