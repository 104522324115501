import API from ".";

const searchMusic = (user, query, type, order_by, instrument, difficulty, time_period, key_signature, composer, offset, ptype, piece_id_to_match, match_time_period, match_pitch, match_rhythm, match_key_sig, match_composer, tags) => {

  query = query ? query : "-"
  type = type ? type : "all"
  order_by = order_by === "shuffle" ? "shuffle" : type === "starred" ? "starred" : type === "viewed" ? "viewed" : "standard"
  instrument = instrument ? instrument : "-"
  difficulty = difficulty ? difficulty : "-"
  time_period = time_period ? time_period : "-"
  key_signature = key_signature ? key_signature : "-"
  composer = composer ? composer : "-"
  tags = tags ? tags : "-"
  offset = offset ? offset : 0
  ptype = ptype ? ptype : "-"

  piece_id_to_match = piece_id_to_match ? piece_id_to_match : "-"
  match_time_period = match_time_period ? match_time_period : 0
  match_pitch = match_pitch ? match_pitch : 0
  match_rhythm = match_rhythm ? match_rhythm : 0
  match_key_sig = match_key_sig ? match_key_sig : 0
  match_composer = match_composer ? match_composer : 0

  let url = '/music/search?query=' + query +
    "&type=" + type +
    "&order_by=" + order_by +
    "&instrument=" + instrument +
    "&difficulty=" + difficulty +
    "&time_period=" + time_period +
    "&key_signature=" + key_signature +
    "&composer=" + composer +
    "&tags=" + tags +
    "&piece_id_to_match=" + piece_id_to_match +
    "&match_time_period=" + match_time_period +
    "&match_difficulty=" + parseInt(0) +
    "&match_pitch=" + match_pitch +
    "&match_rhythm=" + match_rhythm +
    "&match_key_sig=" + match_key_sig +
    "&match_composer=" + match_composer +
    "&ptype=" + ptype +
    "&offset=" + parseInt(offset);

  console.log(url)
  return new API(user).getRequest(url).then(json => {
    console.log(json)
    return { "pieces": json.music, "count": json.count };
  });
}

export default searchMusic;