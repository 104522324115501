const putToS3 = (url, data, fileType) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Length': new Blob([data]).size,
      'Content-Type': fileType === 'pdf' ? 'application/pdf' : 'application/xml'
    },
    body: data
  });
};

export default putToS3