import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Auth } from 'aws-amplify'
import { Hub } from 'aws-amplify';
import user_info from '../types/user';

const AccountContext = React.createContext();

const Account = (props) => {

  const [user, setUser] = useState({ user: null, student: null });

  const [userInfo, setUserInfo] = useState(new user_info({}));

  const getSession = () => {
    return Auth.currentSession(); //currentAuthenticatedUser({bypassCache: true});
  }

  const getCurrentAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  const authenticate = (username, password) => {
    return Auth.signIn(username, password);
  }

  const signup = (email, password, attributes) => {
    return Auth.signUp({
      username: email,
      password: password,
      attributes: attributes,
      autoSignIn: {
        enabled: true,
      }
    });
  }

  const logout = () => {
    return Auth.signOut();
  };

  const confirmSignup = (email, code) => {
    return Auth.confirmSignUp(email, code);
  }

  const resendCode = (email) => {
    return Auth.resendSignUp(email)
  }

  const forgotPassword = (username) => {
    return Auth.forgotPassword(username);
  }

  const forgotPasswordSubmit = (username, code, new_password) => {
    return Auth.forgotPasswordSubmit(username, code, new_password)

  }

  const listenToAutoSignInEvent = (navigation) => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        const user = payload.data;
        setUser({ user: user, student: null, setUser: setUser });
        navigation('/dashboard/music');
      } else if (event === 'autoSignIn_failure') {
        navigation('/signin');
      }
    });
  }

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        signup,
        confirmSignup,
        resendCode,
        forgotPassword,
        forgotPasswordSubmit,
        user,
        setUser,
        getCurrentAuthenticatedUser,
        listenToAutoSignInEvent,
        userInfo,
        setUserInfo
      }}
    >
      <Outlet />
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };