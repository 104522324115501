import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SidebarProvider, SidebarContext } from "./components/Sidebar";
import { AccountContext } from "../stores/Account";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { isKey } from "../utils/json";
import { Navigate } from "react-router-dom";
import Sidebar from './components/Sidebar'
import Header from "./components/Header";
import useWindowDimensions from "../hooks/useWindowDimensions";
import StudentDashboardBanner from "./components/StudentDashboardBanner";
import { useSearchParams } from "react-router-dom";

function DashboardProvider() {
  const { isSidebarOpen, closeSidebar, isExpanded } = useContext(SidebarContext);

  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();

  let location = useLocation()

  useEffect(() => {
    closeSidebar();
  }, [location]);

  const contentStyle = useMemo(() => {
    if (width >= 768 && width <= 1024) {
      return { marginLeft: '64px' } //isExpanded ? '256px' : '64px' };
    }
    return { marginLeft: '0px' };
  }, [isExpanded, width]);

  return (
    <>
      <Header />
      <div className="flex flex-row w-screen h-screen overflow-x-hidden bg-gray-50 dark:bg-gray-900">
        <Sidebar />
        <div className="p-4 overflow-y-scroll overflow-x-hidden no-scrollbar w-full" style={contentStyle}>
          {searchParams.has('ts_stud_id') && <StudentDashboardBanner />}
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default function Dashboard(props) {
  const { user, setUser, getSession } = useContext(AccountContext)

  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getSession().then((us) => {
      setUser((prevUser) => ({ user: us, student: prevUser.student, setUser: setUser }));
      console.log(us.idToken.jwtToken);
      setIsLoading(false);
    }).catch((err) => {
      setUser({ user: null, student: null, setUser: setUser });
      setIsLoading(false);
    });
  }, []);

  // Update user.student if ts_stud_id is in the URL
  useEffect(() => {
    if (searchParams.has('ts_stud_id')) {
      const tsStudId = searchParams.get('ts_stud_id');
      if (user.student !== tsStudId) {
        setUser((prevUser) => ({ ...prevUser, student: tsStudId, setUser: setUser }));
      }
    }
  }, [searchParams, user.student, setUser]);

  // Ensure ts_stud_id is in the URL when user.student changes
  useEffect(() => {
    if (user.student) {
      const currentParams = Object.fromEntries(searchParams);
      if (currentParams.ts_stud_id !== user.student) {
        setSearchParams({ ...currentParams, ts_stud_id: user.student, setUser: setUser });
      }
    }
  }, [user.student, searchParams, setSearchParams]);

  // Add ts_stud_id to URLs on navigation
  useEffect(() => {
    if (user.student) {
      // Listen for navigation changes
      const handleNavigation = () => {
        const currentParams = new URLSearchParams(window.location.search);
        if (currentParams.get('ts_stud_id') !== user.student) {
          const newUrl = `${window.location.pathname}${window.location.search ? '&' : '?'}ts_stud_id=${user.student}`;
          window.history.replaceState(null, '', newUrl);
        }
      };

      window.addEventListener('popstate', handleNavigation);
      return () => window.removeEventListener('popstate', handleNavigation);
    }
  }, [user.student]);

  if (!isLoading) {
    if (user.user && isKey(user.user, "idToken") && isKey(user.user.idToken, "jwtToken")) {
      return <SidebarProvider>
        <DashboardProvider>
          <Outlet />
        </DashboardProvider>
      </SidebarProvider>
    } else {
      return <Navigate to="/signin?failed=true" />;
    }
  }
}
