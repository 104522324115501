import { useState, useRef, useEffect, useContext } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useContainerSize from '../../hooks/useContainerSize';
import PageTurner from './PageTurner';
import PDFLoadingView from './PDFLoadingView';
import KeyboardShortcuts from './KeyboardShortcuts';
import FailedToLoadPDFView from './FailedToLoadPDFView';
import { SystemContext } from '../../stores/System';

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export default function PDFViewer(props) {
  const containerRef = useRef(null);
  const { width } = useContainerSize(containerRef);

  const [activePage, setActivePage] = useState(1);
  const [numPages, setNumPages] = useState(1);

  const [hasError, setHasError] = useState(false);

  const { seenKeyboardShortcuts, setSeenKeyboardShortcuts } = useContext(SystemContext);

  const handleError = (error) => {
    console.error(error);
    setHasError(true);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setActivePage(1);
  }, [props.src])

  console.log("pdf viewer...")
  return (
    <>
      <div className="w-full overflow-auto border border-gray-300 my-4 relative bg-white" style={{ height: props.height ? props.height : '60vh' }} ref={containerRef}>
        {hasError ? (
          <FailedToLoadPDFView width={'100%'} height={'400px'} />
        ) : (
          <Document file={props.src} options={options} onLoadError={handleError} onLoadSuccess={onDocumentLoadSuccess} loading={<PDFLoadingView width={'100vw'} height={'100vh'} />}>
            <Page
              pageNumber={activePage}
              width={width}
              loading={<PDFLoadingView width={'100vw'} height={'100vh'} />}
            />
          </Document>
        )}
        <div className="w-full mt-8 relative px-4 bottom-4 z-10">
          <PageTurner totalPages={numPages} activePage={activePage} setActivePage={setActivePage} />
        </div>
        {props.children}
      </div>
      {!seenKeyboardShortcuts && <KeyboardShortcuts />}
    </>
  );
}