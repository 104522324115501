import React, { useState, useEffect } from 'react';

const SystemContext = React.createContext();

const System = (props) => {
  const [darkMode, setDarkMode] = useState(false);
  const [seenKeyboardShortcuts, setSeenKeyboardShortcuts] = useState(() => {
    return JSON.parse(localStorage.getItem('seenKeyboardShortcuts')) || false;
  });

  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      localStorage.theme = 'dark'
      setDarkMode(true)
    } else {
      document.documentElement.classList.remove('dark')
      localStorage.theme = 'light'
      setDarkMode(false);
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
      localStorage.theme = 'dark';
    } else {
      document.documentElement.classList.remove('dark')
      localStorage.theme = 'light';
    }
  }, [darkMode])

  useEffect(() => {
    localStorage.setItem('seenKeyboardShortcuts', JSON.stringify(seenKeyboardShortcuts));
  }, [seenKeyboardShortcuts]);

  return (
    <SystemContext.Provider
      value={{ darkMode, setDarkMode, seenKeyboardShortcuts, setSeenKeyboardShortcuts }}>
      {props.children}
    </SystemContext.Provider>
  );
};

export { System, SystemContext };