// import React, { useState, useEffect, useRef } from 'react';
// import Flat from 'flat-embed';
// import { CloseButtonIcon } from '../../assets/icons';

// const MultiPageViewFlat = ({ scoreId, onClose }) => {
//     const containerRef = useRef(null);
//     const flatEmbedRef = useRef(null);
//     const [activePage, setActivePage] = useState(1);
//     const [numPages, setNumPages] = useState(0);
//     const [isLoading, setIsLoading] = useState(true); 

//     useEffect(() => {
//         if (containerRef.current) {
//             flatEmbedRef.current = new Flat(containerRef.current, {
//                 score: "66d50904c46d7576d9f42e2f",
//                 embedParams: {
//                     mode: 'edit',
//                     appId: '667c53d0e181f889c06e5f62',
//                     sharingKey: "7c62ddc6faef05d1ac3b4412b14327f69cbed39eaf6eaffac527ba797448f5f49a89e1445153e1465fbdfaf67ad644eac40b321e3a7cde9a8621b4abb93d1b13",
//                     controlsFloating: false,
//                 }
//             });

//             flatEmbedRef.current.load().then(() => {
//                 setIsLoading(false);
//                 setNumPages(flatEmbedRef.current.getNumberOfPages());
//             });

//             const handleKeyDown = (event) => {
//                 if (event.keyCode === 27) {
//                     onClose();
//                 }
//             };

//             document.addEventListener('keydown', handleKeyDown);
//             return () => {
//                 document.removeEventListener('keydown', handleKeyDown);
//             };
//         }
//     }, [scoreId, onClose]);

//     const nextPage = () => {
//         setActivePage(prevPage => Math.min(prevPage + 1, numPages));
//         flatEmbedRef.current?.goToPage(activePage + 1);
//     };

//     const previousPage = () => {
//         setActivePage(prevPage => Math.max(1, prevPage - 1));
//         flatEmbedRef.current?.goToPage(activePage - 1);
//     };

//     return (
//         <div className="fixed inset-0 z-50 flex justify-center items-center">
//             <div className="bg-white p-5 rounded overflow-auto" style={{ height: '100vh', width: '100vw' }} ref={containerRef}>
//                 <div className='flex justify-end'>
//                     <CloseButtonIcon onClick={onClose} className="w-5 h-5 cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-150" />
//                 </div>
//                 <div ref={containerRef} style={{ width: '100%', height: '90vh' }} />
//                 {!isLoading && (
//                     <div className="flex absolute bottom-0 items-center justify-evenly w-full p-4 bg-gray-200">
//                         <button onClick={previousPage} type="button" className="mr-10 text-gray-400 hover:text-gray-500 font-thin rounded-lg text-sm p-2.5 text-center inline-flex items-center transition-all duration-150">
//                             <svg className="w-5 h-5 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
//                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
//                             </svg>
//                         </button>
//                         <button onClick={nextPage} type="button" className="ml-10 text-gray-400 hover:text-gray-500 font-thin rounded-lg text-sm p-2.5 text-center inline-flex items-center transition-all duration-150">
//                             <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
//                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
//                             </svg>
//                         </button>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default MultiPageViewFlat;

import React from 'react';
import { CloseButtonIcon } from '../../assets/icons';

const MultiPageViewFlat = ({ onClose, title, composer, sharingKey, scoreId }) => {
    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center">
            <div className="bg-white p-5 rounded overflow-hidden" style={{ height: '100vh', width: '100vw' }}>
                <div className='flex justify-between items-center w-full px-4 py-2'>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <h2 className="text-xl font-bold">{title}</h2>
                        <p className="text-sm">{composer}</p>
                    </div>
                    <CloseButtonIcon onClick={onClose} className="w-5 h-5 cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-150" />
                </div>
                <iframe
                    src={`https://flat.io/embed/${scoreId}?_l=true&sharingKey=${sharingKey}&layout=responsive&parts=366f8f5e-976b-3ba8-a693-260f3e44e01f&displayFirstLinePartsNames=false&branding=false&noHeader=true&appId=667c53d0e181f889c06e5f62`}
                    height="90%"
                    width="100%"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay; midi">
                </iframe>
                <div style={{ fontSize: '11px', color: '#3981FF', marginTop: '4px', fontFamily: 'Inter, Helvetica Neue, Helvetica, Arial, sans-serif' }}>
                    View on <a href="https://flat.io" target="_blank" style={{ color: '#3981FF', textDecoration: 'none' }}>Flat: </a>
                    <a href={`https://flat.io/score/${scoreId}?sharingKey=${sharingKey}`} target="_blank" style={{ color: '#3981FF', textDecoration: 'none' }}>{title}</a>
                </div>
            </div>
        </div>
    );
};

export default MultiPageViewFlat;
