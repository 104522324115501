import API from ".";
import { getCurrentTimeFormatted } from "../utils/datetime";

const logPractice = (user, start, end, piece_id) => {
  // Convert milliseconds timestamps to formatted strings
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Format dates as YYYY-MM-DD HH:mm:ss
  const startFormatted = getCurrentTimeFormatted(startDate);
  const endFormatted = getCurrentTimeFormatted(endDate);

  // Calculate total minutes between start and end
  const total_time = Math.floor((end - start) / (1000 * 60));

  let obj = {
    "started_time": startFormatted,
    "ended_time": endFormatted,
    "page": 0, // page is 0 for now because we can't track them
    "piece_id": piece_id,
    "total_time": total_time
  }

  return new API(user).postRequest('/practice/log', obj);
}

export default logPractice;