import React, { useState, useEffect, useContext } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import practiceDistribution from '../../api/practiceDistribution';
import { AccountContext } from '../../stores/Account';
// Register the required chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PiecesPlayedChart = ({ timeRange = 7, onTimeRangeChange, student_id }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const timeRanges = [
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 3 months', value: 90 },
    { label: 'Last year', value: 365 },
  ];

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const { user } = useContext(AccountContext);
  useEffect(() => {
    practiceDistribution(user, timeRange, student_id).then((dat) => {
      setLabels(dat.labels || []);
      setData(dat.data || []);
    });
  }, [timeRange, student_id]);


  const pieData = {
    labels: labels,
    datasets: [{
      label: 'Practice Time Proportion',
      data: data,
      backgroundColor: [
        '#6A11CB',
        '#3A1C71',
        '#D76D77',
        '#FFAF7B'
      ],
      borderColor: [
        '#6A11CB',
        '#3A1C71',
        '#D76D77',
        '#FFAF7B'
      ],
      borderWidth: 1,
      cutout: '70%'
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}%`;
          }
        }
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Practice Distribution
        </h3>
        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
            type="button"
          >
            {timeRanges.find(r => r.value === timeRange)?.label}
            <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-44 bg-white dark:bg-gray-700 rounded-lg shadow-lg z-10">
              {timeRanges.map((range) => (
                <button
                  key={range.value}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    onTimeRangeChange(range.value);
                  }}
                  className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600
                    ${timeRange === range.value ? 'text-violet-600 dark:text-violet-400' : 'text-gray-700 dark:text-gray-200'}`}
                >
                  {range.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="relative h-[300px]">
        <Doughnut data={pieData} options={options} />
      </div>

      <div className="mt-6 flex flex-wrap gap-4">
        {pieData.labels.map((label, index) => (
          <div key={label} className="flex items-center">
            <div
              className="w-4 h-4 rounded-full mr-2"
              style={{ backgroundColor: pieData.datasets[0].backgroundColor[index] }}
            />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PiecesPlayedChart; 