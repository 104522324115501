import API from ".";

const createTag = (user, piece_id, tag) => {

  let obj = {
    "piece_id": piece_id,
    "tag_name": tag
  }

  return new API(user).postRequest('/piece/tag', obj);
}

export default createTag;