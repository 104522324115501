import API from ".";

const updateDrillNotes = (user, drill_id, notes) => {

  let obj = {
    "drill_id": drill_id,
    "drill_notes": notes
  }

  console.log(obj)
  return new API(user).postRequest('/drill/notes', obj);
}

export default updateDrillNotes;