import API from ".";

const listDrills = (user, filter_by_id, filter_by_name, offset) => {

  filter_by_id = filter_by_id ? filter_by_id : "-"
  filter_by_name = filter_by_name ? filter_by_name : "-"
  offset = offset ? offset : 0

  let url = '/drill/list?filter_by_id=' + filter_by_id +
    "&filter_by_name=" + filter_by_name +
    "&offset=" + parseInt(offset);

  return new API(user).getRequest(url).then(json => {
    console.log("Drills data:", json);
    return { "drills": json.drills, "count": json.count };
  });
}

export default listDrills;