import React, { useContext, useState, useEffect } from 'react'
import { SidebarContext } from './Sidebar';
import {
  MoonIcon,
  SunIcon,
  MenuIcon,
  OutlinePersonIcon,
  OutlineLogoutIcon,
} from '../../assets/icons'
import Avatar from '../../components/Avatar';
import { Dropdown, DropdownItem } from '../../components/Dropdown';
import { SystemContext } from '../../stores/System';
import { AccountContext } from '../../stores/Account';
import { useNavigate } from 'react-router-dom';
import getUserInfo from '../../api/getUserInfo';

function Header() {
  const { toggleSidebar } = useContext(SidebarContext)

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  const { darkMode, setDarkMode } = useContext(SystemContext);

  const { logout, setUserInfo, userInfo, user, setUser } = useContext(AccountContext);

  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen)
  }

  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo(user).then((obj) => {
      setUserInfo(obj);
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  return (
    <header className="z-40 bg-white shadow-bottom dark:bg-gray-800 h-16 md:hidden">
      <div className="flex items-center justify-between h-full px-6 text-violet-600 dark:text-violet-600">
        <a href="/" className="h-8 mx-auto z-10 bg-white dark:bg-gray-800">
          {/* Mobile Logo */}
          <img className="ml-1 h-8 lg:hidden" src="/SubitoLogoNoText.png" alt="Mobile Logo" />
          {/* Desktop Logo */}
          <img className="ml-1 h-8 hidden lg:block" src="/SubitoLogo.png" alt="Desktop Logo" />
        </a>
        {/* <!-- Mobile hamburger --> */}
        <div className="flex-grow"></div>
        <button
          className="p-1 rounded-md lg:invisible focus:outline-none focus:shadow-outline-violet"
          onClick={toggleSidebar}
          aria-label="Menu"
        >
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>

        {/*<nav className="flex px-5 py-1 text-gray-700 border border-gray-200 rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700 hidden sm:block" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li className="inline-flex items-center">
              <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                </svg>
                Home
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <svg className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                </svg>
                <a href="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Templates</a>
              </div>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                </svg>
                <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Flowbite</span>
              </div>
            </li>
          </ol>
        </nav>*/}

        <ul className="flex items-center flex-shrink-0 space-x-6">
          {/* <!-- Theme toggler --> */}
          {/* <li className="flex">
            <button
              className="rounded-md focus:outline-none focus:shadow-outline-violet"
              onClick={() => setDarkMode(!darkMode)}
              aria-label="Toggle color mode"
            >
              {!darkMode ? (//mode === 'dark' ? (
                <SunIcon className="w-5 h-5" aria-hidden="true" />
              ) : (
                <MoonIcon className="w-5 h-5" aria-hidden="true" />
              )}
            </button>
          </li> */}
          {/* <!-- Profile menu --> */}
          {/* <li className="relative">
            <button
              className="rounded-full focus:shadow-outline-violet focus:outline-none"
              onClick={handleProfileClick}
              aria-label="Account"
              aria-haspopup="true"
            >
              <Avatar
                className="align-middle"
                src={userInfo.profile_pic}
                alt=""
                aria-hidden="true"
              />
            </button>
            <Dropdown
              align="right"
              size="w-48"
              isOpen={isProfileMenuOpen}
              onClose={() => setIsProfileMenuOpen(false)}
            >
              <DropdownItem tag="a" href="#">
                <OutlinePersonIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                <div>
                  <p>{userInfo.first_name} {userInfo.last_name}</p>
                  <p className="ml-auto text-xs font-medium text-gray-500 dark:text-gray-400 text-violet-600 hover:underline dark:text-violet-600">{userInfo.email}</p>
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => { logout().then(() => { setUser(null); navigate('/') }); }}>
                <OutlineLogoutIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                <span>Log out</span>
              </DropdownItem>
            </Dropdown>
          </li> */}
        </ul>
      </div>
    </header>
  )
}

export default Header
