import API from ".";

const confirmStudent = (user, student_id, decision) => {

  let obj = {
    "student_id": student_id,
    "decision": decision
  }

  return new API(user).postRequest('/teacher/confirm', obj);
}

export default confirmStudent;  