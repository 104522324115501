import SubitoLogo from '../../components/SubitoLogo';

export default function FailedToLoadPDFView({ width, height }) {
    return (
        <div role="status" className="bg-white flex items-center justify-center" style={{ width: width, height: height }}>
            <div className="flex flex-col items-center justify-center">
                <a className="mb-2">
                    <SubitoLogo />
                </a>
                <p className="text-sm text-black font-thin">Failed to load music score</p>
            </div>
        </div>
    )
}
