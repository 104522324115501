import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getInitial } from "../../utils/string";
import getDrill from '../../api/getDrill';
import { AccountContext } from '../../stores/Account';
import FlatEmbed from '../components/FlatEmbed';
import MultiPageViewFlat from '../components/MultiPageViewFlat';
import viewDrill from '../../api/viewDrill';
import updateDrillNotes from '../../api/updateDrillNotes';
const Drill = () => {
    const { id } = useParams();
    const [drill, setDrill] = useState(null);
    const { user } = useContext(AccountContext);
    const [activeTab, setActiveTab] = useState('view');
    const [fullScreenFlat, setFullScreenFlat] = useState(false);
    const [isEditingNotes, setIsEditingNotes] = useState(false);
    const [notes, setNotes] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        getDrill(user, id).then((drill) => {
            console.log(drill);
            setDrill(drill.drill);
            setNotes(drill.drill.drill_notes || '');
        }).catch((error) => {
            console.error(error);
        });
    }, [id]);

    useEffect(() => {
        if (drill) {
            viewDrill(user, drill.id)
        }
    }, [drill])

    const handleUpdateNotes = async () => {
        setIsUpdating(true);
        try {
            await updateDrillNotes(user, drill.id, notes);
            setDrill({ ...drill, drill_notes: notes });
            setIsEditingNotes(false);
        } catch (error) {
            console.error('Failed to update notes:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    return drill ? (
        <div className="w-full overflow-hidden">
            <div className="box-border overflow-x-hidden shadow-md w-full rounded-lg dark:bg-gray-700 bg-white">
                <div className="flex flex-col">
                    {/* Header */}
                    <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-600">
                        <div className="flex items-center space-x-4">
                            <div>
                                <h1 className="text-xl font-bold text-gray-900 dark:text-white">
                                    {drill.drill_name}
                                </h1>
                                <div className="flex items-center gap-4 mt-1">
                                    <p className="text-sm text-gray-600 dark:text-gray-300">
                                        From <span className="font-medium">{drill.piece_title}</span>
                                    </p>
                                    <span className="text-gray-300 dark:text-gray-600">•</span>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        Created {new Date(drill.created_time).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                            Last viewed {new Date(drill.last_viewed).toLocaleDateString()}
                        </div>
                    </div>

                    {/* Tabs */}
                    <div className="px-4 py-3 flex justify-between items-center">
                        <div className="flex flex-wrap gap-3">
                            <button
                                onClick={() => setActiveTab('view')}
                                className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'view'
                                    ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                                    : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                                    }`}
                            >
                                <span className="whitespace-nowrap">View</span>
                            </button>
                            <button
                                onClick={() => setActiveTab('notes')}
                                className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${activeTab === 'notes'
                                    ? 'text-white bg-violet-600 hover:bg-violet-700 shadow-sm'
                                    : 'text-gray-600 bg-white border border-gray-200 hover:bg-gray-50 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white'
                                    }`}
                            >
                                <span className="whitespace-nowrap">Notes</span>
                            </button>
                        </div>
                    </div>

                    {/* Tab Content */}
                    <div className="p-6">
                        {activeTab === 'view' ? (
                            <>
                                {fullScreenFlat && (
                                    <MultiPageViewFlat
                                        title={drill.drill_name}
                                        composer={`Subito Exercise`}
                                        scoreId={drill.flat_score_id}
                                        sharingKey={drill.flat_sharing_key}
                                        onClose={() => setFullScreenFlat(false)}
                                    />
                                )}
                                <div className="relative">
                                    <div className="top-4 right-4 absolute z-10">
                                        <svg
                                            onClick={() => setFullScreenFlat(true)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
                                        </svg>
                                    </div>
                                    <FlatEmbed
                                        scoreId={drill.flat_score_id}
                                        sharingKey={drill.flat_sharing_key}
                                        height={600}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="p-6">
                                <div className="flex items-center justify-between mb-4">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                        Notes
                                    </h3>
                                    <div className="flex space-x-3">
                                        {isEditingNotes ? (
                                            <>
                                                <button
                                                    onClick={() => {
                                                        setNotes(drill.notes || '');
                                                        setIsEditingNotes(false);
                                                    }}
                                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                                    disabled={isUpdating}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={handleUpdateNotes}
                                                    disabled={isUpdating}
                                                    className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200 disabled:opacity-50"
                                                >
                                                    {isUpdating ? 'Saving...' : 'Save Changes'}
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                onClick={() => setIsEditingNotes(true)}
                                                className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                                            >
                                                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                                Edit Notes
                                            </button>
                                        )}
                                    </div>
                                </div>
                                {isEditingNotes ? (
                                    <textarea
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        rows="4"
                                        className="w-full p-3 text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        placeholder="Add notes about this drill..."
                                    />
                                ) : (
                                    <div className="text-gray-600 dark:text-gray-300">
                                        {drill.drill_notes || "This drill has no notes yet."}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
        </div>
    );
}

export default Drill;