import API from ".";

const getRecentPieces = (user) => {
  let url = '/music/recent';

  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getRecentPieces;