import API from ".";

const updateAlbumMetadata = (user, album_id, name, picture) => {
  let url = '/album/update';

  return new API(user).postRequest(url, {
    album_id: album_id,
    name: name,
    picture: picture
  });
}

export default updateAlbumMetadata;