import API from ".";

const getPresignedPost = (user, n, fileType) => {
  if (!n || n === null || n === undefined) {
    n = 1;
  }

  let url = '/piece/bulkurls?n=' + n.toString() + '&file_type=' + fileType;

  return new API(user).getRequest(url).then(json => {
    return json;
  });
}

export default getPresignedPost;