import Modal from "../../components/Modal"
import React, { useState, useEffect, useContext } from 'react'
import { SystemContext } from "../../stores/System"

function KeyboardShortcuts(props) {
  const [showModal, setShowModal] = useState(true);
  const { setSeenKeyboardShortcuts } = useContext(SystemContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (showModal) {
        closeModal();
      }
    }, 30000);

    return () => clearTimeout(timer);
  }, [showModal, setSeenKeyboardShortcuts]);

  const closeModal = () => {
    setShowModal(false);
    setSeenKeyboardShortcuts(true);
  };

  return (
    showModal && (
      <div className="fixed bottom-8 right-8 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 z-50">
        <div className="flex justify-between items-center">
          <div className="relative overflow-x-auto rounded-lg">
            <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Key
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <button onClick={closeModal} className="absolute top-2 right-2 text-gray-400 hover:text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <kbd className="px-5 py-1 text-md font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">←</kbd>
                  </th>
                  <td className="px-6 py-4">
                    Go to the previous page
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <kbd className="px-5 py-1 text-md font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">→</kbd>
                  </th>
                  <td className="px-6 py-4">
                    Go to the next page
                  </td>
                </tr>
                <tr className="bg-white dark:bg-gray-900">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <kbd className="px-5 py-1 text-md font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">↑</kbd>
                  </th>
                  <td className="px-6 py-4">
                    Scroll up
                  </td>
                </tr>
                <tr className="bg-white dark:bg-gray-900">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <kbd className="px-5 py-1 text-md font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">↓</kbd>
                  </th>
                  <td className="px-6 py-4">
                    Scroll down
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                    <kbd className="px-4 py-2 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">esc</kbd>
                  </th>
                  <td className="px-6 py-4">
                    Close the viewer
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  )
}

export default KeyboardShortcuts
