import API from ".";

const saveUserInfo = (user, userInfo) => {
  let url = '/user/save';

  console.log('user info', userInfo);
  return new API(user).postRequest(url, {
    first_name: userInfo.first_name,
    last_name: userInfo.last_name,
    instrument: userInfo.instrument,
    profile_pic: userInfo.profile_pic,
    student_level: userInfo.student_level,
    street_addr: userInfo.street_addr,
    city_addr: userInfo.city_addr,
    state_addr: userInfo.state_addr,
    country_addr: userInfo.country_addr,
    zipcode_addr: userInfo.zipcode_addr,
    ts_stud_id: userInfo.ts_stud_id,
    ts_flag: userInfo.ts_flag
  });
}

export default saveUserInfo;