import React, { useState, useContext } from 'react';
import Modal from '../../components/Modal';
import { AccountContext } from '../../stores/Account';
import affiliateWithTeacher from '../../api/affiliateWithTeacher';

function TeacherAffiliationModal({ button_view, setDisabled }) {
  const [teacherCode, setTeacherCode] = useState('');
  const { user } = useContext(AccountContext);

  const submit = async () => {
    try {
      await affiliateWithTeacher(user, teacherCode);
      window.location.reload();
    } catch (err) {
      console.error('Failed to affiliate with teacher:', err);
    }
  };

  return (
    <Modal
      title="Affiliate with Teacher"
      button_text="Affiliate"
      submit={submit}
      onModalClose={() => setDisabled?.(false)}
      onModalOpen={() => setDisabled?.(true)}
      button_view={button_view}
    >
      <div className="space-y-4">
        <div>
          <label
            htmlFor="teacher-code"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Teacher Code
          </label>
          <input
            id="teacher-code"
            type="text"
            value={teacherCode}
            onChange={(e) => setTeacherCode(e.target.value)}
            placeholder="Enter your teacher's code"
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          />
        </div>
      </div>
    </Modal>
  );
}

export default TeacherAffiliationModal; 