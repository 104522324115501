import { useState } from 'react';
import PageIllustration from '../../landing/components/PageIllustration';
import OpenSheetMusicDisplay from '../components/OSMD';
import mockMusicXML from '../../test/mockMusicXML';

const MusicGenerationUI = () => {
  const [mode, setMode] = useState('Text Seed');
  const [inputValue, setInputValue] = useState('');
  const [keySignature, setKeySignature] = useState('');
  const [timeSignature, setTimeSignature] = useState('');
  const [technique, setTechnique] = useState('');

  const keySignatures = ['C', 'G', 'D', 'A', 'E', 'B', 'F#', 'C#', 'F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb', 'Cb'];
  const timeSignatures = ['4/4', '3/4', '2/4', '6/8', '9/8', '12/8'];
  const techniques = ['Arpeggio', 'Scale', 'Third', 'Fifth', 'Sixth', 'Octave'];

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setInputValue('');
    setKeySignature('');
    setTimeSignature('');
    setTechnique('');
  };

  return (
    <div className="shadow-md w-full rounded-lg bg-white dark:bg-gray-800 p-4 mb-6 mt-6 transition-colors duration-200">
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Generate Music from {mode}</h2>
        <div className="space-x-2">
          {mode !== 'Parameters' && (
            <button
              onClick={() => handleModeChange('Parameters')}
              className="px-3 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
            >
              Parameters
            </button>
          )}
          {mode !== 'Input Seed' && (
            <button
              onClick={() => handleModeChange('Input Seed')}
              className="px-3 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
            >
              Input Seed
            </button>
          )}
          {mode !== 'Text Seed' && (
            <button
              onClick={() => handleModeChange('Text Seed')}
              className="px-3 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
            >
              Text Seed
            </button>
          )}
        </div>
      </div>

      {mode === 'Text Seed' && (
        <input
          type="text"
          placeholder="Enter your text seed."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400 bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
        />
      )}

      {mode === 'Parameters' && (
        <div className="flex space-x-2">
          <select
            value={keySignature}
            onChange={(e) => setKeySignature(e.target.value)}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400 bg-white dark:bg-gray-700 text-gray-900 dark:text-white transition-colors duration-200"
          >
            <option value="">Key Signature</option>
            {keySignatures.map((key) => (
              <option key={key} value={key}>{key}</option>
            ))}
          </select>
          <select
            value={timeSignature}
            onChange={(e) => setTimeSignature(e.target.value)}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400 bg-white dark:bg-gray-700 text-gray-900 dark:text-white transition-colors duration-200"
          >
            <option value="">Time Signature</option>
            {timeSignatures.map((time) => (
              <option key={time} value={time}>{time}</option>
            ))}
          </select>
          <select
            value={technique}
            onChange={(e) => setTechnique(e.target.value)}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400 bg-white dark:bg-gray-700 text-gray-900 dark:text-white transition-colors duration-200"
          >
            <option value="">Technique</option>
            {techniques.map((tech) => (
              <option key={tech} value={tech}>{tech}</option>
            ))}
          </select>
        </div>
      )}

      {mode === 'Input Seed' && (
        <div className="relative">
          <input
            type="file"
            onChange={(e) => setInputValue(e.target.value)}
            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
          />
          <div className="w-full p-2 border border-dashed border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 flex items-center justify-center">
            <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <span>{inputValue || "Click to upload or drag and drop"}</span>
          </div>
        </div>
      )}

      <button
        className="mt-4 w-full bg-violet-600 text-white p-2 rounded-md hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
      >
        Generate
      </button>
    </div>
  );
};

const StaticPreviousInput = () => {

  const [isActive, setIsActive] = useState(false);


  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      onClick={handleClick}
      className={`shadow-md w-full rounded-lg bg-white dark:bg-gray-800 p-4 pb-2 transition-colors duration-200 ${!isActive && "hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
        }`}
    >
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Parameters</h2>
        <div className="space-x-2">
          <button
            className="px-3 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md cursor-default transition-colors duration-200"
          >
            Text Seed
          </button>
          <button
            className="px-3 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md cursor-default transition-colors duration-200"
          >
            Input Seed
          </button>
        </div>
      </div>

      <div className="flex space-x-2">
        <div className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-700 transition-colors duration-200">
          C Major
        </div>
        <div className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-700 transition-colors duration-200">
          4/4
        </div>
        <div className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-700 transition-colors duration-200">
          Arpeggio
        </div>
      </div>
      {isActive && (
        <div className="w-full overflow-x-hidden">
          <OpenSheetMusicDisplay music={mockMusicXML} measures={10} hidePagination={false} />
        </div>
      )}
    </div>
  );
};


function AIDemoPage() {

  const oldGens = [];

  return (
    <div className="w-full flex flex-col">
      <div className="relative w-full mx-auto h-0 pointer-events-none" aria-hidden="true">
        <PageIllustration />
      </div>
      {oldGens.length > 0 ?
        <>
          <div className="flex flex-col gap-y-4">
            {oldGens.map(() =>
              <>
                <StaticPreviousInput />
              </>
            )}
          </div>
          <MusicGenerationUI />
        </>
        : <div className={`w-full flex flex-col items-center justify-end h-[calc(100vh-2rem)]`}>
          <div className="flex flex-col justify-center items-center mb-36">
            <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl"><span className="text-transparent bg-clip-text bg-gradient-to-r from-violet-600 to-purple-400">Practice Better</span> With AI.</h1>
            <p className="text-lg font-normal text-center max-w-4xl text-gray-900 lg:text-xl dark:text-white">At Subito, we want to complement awesome private music teachers with a dedicated suite to make musician practice more efficient, rewarding, and accessible.</p>
          </div>
          <MusicGenerationUI />
        </div>
      }
    </div>
  );
}


export default AIDemoPage;