import React, { useState, useEffect, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import useContainerSize from '../../hooks/useContainerSize';
import { CloseButtonIcon } from '../../assets/icons';
import PDFLoadingView from './PDFLoadingView';

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const MultiPageViewPDF = ({ file, onClose }) => {
    const containerRef = useRef(null);
    const { width } = useContainerSize(containerRef);

    const [activePage, setActivePage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setIsLoading(false);
    }

    function nextPage() {
        setActivePage(prevPage => Math.min(prevPage + 1, numPages));
    }

    function previousPage() {
        setActivePage(prevPage => Math.max(1, prevPage - 1));
    }

    function handleScroll(direction) {
        if (containerRef.current) {
            const currentScroll = containerRef.current.scrollTop;
            const scrollAmount = window.innerHeight / 3;

            containerRef.current.scrollTo({
                top: direction === 'up' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowRight':
                    nextPage();
                    break;
                case 'ArrowLeft':
                    previousPage();
                    break;
                case 'ArrowUp':
                    handleScroll('up');
                    break;
                case 'ArrowDown':
                    handleScroll('down');
                    break;
                case 'Escape':
                    onClose();
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [numPages, onClose]);

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center">
            <div className="bg-white p-5 rounded overflow-auto relative" style={{ height: '100vh', width: '100vw' }} ref={containerRef}>
                <div className='flex justify-end absolute top-5 right-5 z-50'>
                    <CloseButtonIcon onClick={onClose} className="w-10 h-10 cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-150" />
                </div>
                <Document file={file} options={options} onLoadSuccess={onDocumentLoadSuccess} loading={<PDFLoadingView width={width - 40} height={'100vh'} />}>
                    <div className="flex justify-center">
                        <Page pageNumber={activePage} width={width - 40} loading={<PDFLoadingView width={width - 40} height={'100vh'} />} />
                    </div>
                </Document>
                {!isLoading && (
                    <div className="flex relative items-center justify-center w-full p-4">
                        <button onClick={previousPage} type="button" className="mr-10 text-gray-400 hover:text-gray-500 font-thin rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 transition-all duration-150">
                            <svg className="w-5 h-5 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>
                        <button onClick={nextPage} type="button" className="ml-10 text-gray-400 hover:text-gray-500 font-thin rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 transition-all duration-150">
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
};

export default MultiPageViewPDF;
