import API from ".";
import user_info from "../types/user";

const getUserInfo = (user) => {
  let url = '/user/get';

  return new API(user).getRequest(url).then(json => {
    console.log(json);
    return new user_info(json);
  });
}

export default getUserInfo;