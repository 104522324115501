import API from ".";

const deleteAlbum = (user, album) => {

  let obj = {
    "album_id": album
  }

  return new API(user).postRequest('/album/delete', obj);
}

export default deleteAlbum;