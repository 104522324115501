import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

//allowed is either null/undefined, a list of allowed string values, or the type 'int'
function useStateParam(paramName, allowed, def = undefined) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [param, setParam] = useState(() => {
    const p = searchParams.get(paramName);
    if (allowed) {
      if (allowed === "int") {
        try {
          let val = parseInt(p);
          if (isNaN(val)) {
            if (def !== undefined && def !== null) {
              return def
            }
            return 1
          }
          return val
        } catch {
          return def !== undefined && def !== null ? def : 1
        }
      } else if (allowed === "bool") {
        try {
          return new Boolean(p)
        } catch { return def ? def : new Boolean(false) }
      }
      else if (!(allowed.includes(p))) return def
    }
    return p;
  });

  useEffect(() => {
    if (param !== searchParams.get(paramName)) {
      // Create a new URLSearchParams object to avoid mutation
      const newSearchParams = new URLSearchParams(searchParams.toString());

      if (param && param !== "-" && param !== "" && param !== null) {
        newSearchParams.set(paramName, param);
      } else {
        newSearchParams.delete(paramName);
      }

      // Use replace: true to avoid adding to browser history
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [param, paramName, searchParams, setSearchParams]);

  const setParamWithBatch = (newValue) => {
    // Use setTimeout to break out of React's batching
    setTimeout(() => {
      setParam(newValue);
    }, 0);
  };

  return [param, setParamWithBatch];
}

export default useStateParam;