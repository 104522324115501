import React from 'react';
import SignIn from '../authentication/login';
import SignUp from '../authentication/signup';
import Home from '../landing/Home';
import { createBrowserRouter, createRoutesFromElements, Link, Route, RouterProvider, Navigate } from 'react-router-dom';
import { Account } from '../stores/Account';
import { System } from '../stores/System';
import ForgotPassword from '../authentication/forgotPassword';
import ResetPassword from '../authentication/resetPassword';
import Dashboard from '../dashboard/Dashboard';
import Profile from '../dashboard/pages/Profile';
import Music from '../dashboard/pages/Music';
import { aws_config } from '../config/config';
import { Amplify } from 'aws-amplify';
import ConfirmEmail from '../authentication/confirmEmail';
import { ErrorBoundary } from 'react-error-boundary';
import { Error404, Error500 } from './ErrorBoundaryPage'
import { pdfjs } from 'react-pdf';
import FlatEmbed from '../dashboard/components/FlatEmbed';
import Album from '../dashboard/pages/Album';
import Piece from '../dashboard/pages/Piece';
import AIDemo from '../dashboard/pages/AIDemo';
import TeacherDashboard from '../dashboard/pages/TeacherDashboard';
import Exercises from '../dashboard/pages/Exercises';
import Drill from '../dashboard/pages/Drill';

Amplify.configure({
  Auth: aws_config
})

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const App = () => {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Account />}>
        <Route exact path="*" element={<Error404 />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/confirm-email" element={<ConfirmEmail />} />
        <Route exact path="/flatembed" element={<FlatEmbed />} />
        <Route exact path="/dashboard" element={<Dashboard />}>
          <Route path="/dashboard/music/piece/:id" element={<Piece />} />
          <Route path="/dashboard/music/album/:id" element={<Album />} />
          <Route path="/dashboard/music" element={<Music />} />
          <Route path="/dashboard/aidemo" element={<AIDemo />} />
          {/*<Route path="/dashboard/sightreading" element={<Sightreading />} />
          {/*<Route path="/dashboard/profile" element={<Profile />} />*/}
          <Route path="/dashboard/home" element={<Profile />} />
          <Route path="/dashboard/teacherdashboard" element={<TeacherDashboard />} />
          <Route path="/dashboard/exercises" element={<Exercises />} />
          <Route path="/dashboard/exercises/:id" element={<Drill />} />
          <Route path="/dashboard/*" element={<Navigate to="/error" />} />
        </Route>
      </Route>
    )
  );

  const handleError = (error) => {
    return <Error500 error={error} />;
  };

  return (
    <System>
      <ErrorBoundary fallbackRender={handleError}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </System>
  );
}

export default App